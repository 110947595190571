import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
// mui
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import Stack from '@mui/material/Stack';

// icons
import { ErrorOutlineOutlined, CheckCircleOutlineOutlined } from '@mui/icons-material';
// utils
import ErrorView, { errorAlert } from 'src/utils/error-dialog';
import { fCurrency } from 'src/utils/format-number';
import Items from 'src/components/items/items';
import { getUuid } from 'src/utils/get-uuid';
// Services
import ApplicationService from 'src/services/application';

export default function Result() {
  const navigate = useNavigate();

  const uuid = getUuid();

  const [currentUser, setCurrentUser] = useState(undefined);

  const [openDialog, setOpenDialog] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [errorTitle, setErrorTitle] = useState('');
  const [closeFunction, setCloseFunction] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await ApplicationService.get(uuid);
        setCurrentUser(response);
      } catch (error) {
        errorAlert(
          'Ocurrio un error inesperado',
          'Ha ocurrido un error inesperado al cargar la pagina',
          setErrorMsg,
          setErrorTitle,
          setOpenDialog
        );
        console.error('Error fetching client info:', error);
      }
    };

    fetchData();
    // reRender is here to ensure the useEffect executing when the component re-renders
  }, [uuid]);

  let quote = 0;
  if (currentUser) {
    quote = currentUser.finance_amount / currentUser.term;
  }

  let result;
  if (currentUser) {
    if (!currentUser.recomendation) {
      result = 'ACEPTAR';
    }

    result = currentUser.recomendation;

    if (currentUser.process === 'bank' || currentUser.process === 'documents') {
      result = 'ACEPTAR';
    }
  }

  const resultData = [
    { label: 'Nombre', key: 'name' },
    { label: 'Apellido', key: 'surname' },
    { label: 'Importe', key: 'finance_amount', function: fCurrency },
    { label: 'Plazo', key: 'term' },
    { label: 'Cuota', key: 'quote' },
    { label: 'ID de la transacción', key: '71655' },
  ];

  const handleClose = () => {
    navigate(`/clients/payment/${uuid}`);
  };

  console.log('Current user:', currentUser);
  const renderData = (
    <Card sx={{ alignItems: 'center', p: 1.5, pt: 1.5 }} style={{ height: '100%', width: '100%' }}>
      <CardHeader title="Resultado" sx={{ p: 0, textAlign: 'center' }} />
      <Divider sx={{ m: 1, mx: 0, borderStyle: 'dashed' }} />
      <Stack spacing={1} direction="row" justifyContent="space-around">
        <Stack width="55%">
          {currentUser && (
            <>
              {resultData.slice(0, 3).map((item, index) => (
                <Items
                  value={
                    item.function ? item.function(currentUser[item.key]) : currentUser[item.key]
                  }
                  title={item.label}
                  key={index}
                />
              ))}
              {resultData.slice(3, 4).map((item, index) => (
                <Items
                  value={`${
                    item.function ? item.function(currentUser[item.key]) : currentUser[item.key]
                  } meses`}
                  title={item.label}
                  key={index}
                />
              ))}
            </>
          )}
          {resultData.slice(4, 5).map((item, index) => {
            const formattedValue = parseFloat(quote).toFixed(2);
            return <Items value={`${formattedValue} €/mes`} title={item.label} key={index} />;
          })}
          {resultData.slice(5, 6).map((item, index) => (
            <Items value={item.key} title={item.label} key={index} />
          ))}
        </Stack>
        <Stack>
          <CardMedia
            component="img"
            sx={{ height: 'auto', width: '150px', alignSelf: 'center' }}
            image="/assets/images/QR_Code_Example.svg.png"
            alt="green iguana"
          />
        </Stack>
      </Stack>
      <Stack sx={{ p: 3 }}>
        <Typography sx={{ fontSize: '0.7rem' }} textAlign="justify">
          Este cargo se realizará automáticamente en las fechas acordadas, de acuerdo con el plan de
          pagos seleccionado. Usted acepta que es su responsabilidad asegurarse de que haya
          suficientes fondos disponibles en la cuenta asignada para cubrir el pago recurrente. El
          incumplimiento de los pagos recurrentes puede resultar en cargos adicionales y la
          suspensión del servicio. Para cancelar o modificar los pagos recurrentes, por favor
          póngase en contacto con nuestro servicio de atención al cliente.
        </Typography>
      </Stack>
    </Card>
  );

  const success = (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          backgroundColor: '#d4edda',
          padding: '20px',
          borderRadius: '10px',
        }}
      >
        <CheckCircleOutlineOutlined sx={{ fontSize: '48px', color: '#28a745' }} />
        <Typography variant="h5" align="center" sx={{ color: '#28a745', my: 2 }}>
          ¡Aprobado!
        </Typography>
        {currentUser && (
          <Typography variant="body1" align="center" sx={{ color: '#28a745', mb: 1 }}>
            ¡Enhorabuena, su solicitud por {currentUser.finance_amount}€ en un plazo de{' '}
            {currentUser.term} meses ha sido aprobada!
          </Typography>
        )}

        <Grid container sx={{ mt: 1, display: 'flex', justifyContent: 'space-evenly' }}>
          {currentUser !== 'undefined' ? renderData : <Typography>Cargando información</Typography>}
        </Grid>
      </Box>
      <Button
        variant="contained"
        sx={{ mt: 4, width: '100%', borderRadius: 8 }}
        onClick={handleClose}
      >
        Siguiente
      </Button>
    </>
  );

  const denied = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        backgroundColor: '#f8d7da',
        padding: '20px',
        borderRadius: '10px',
      }}
    >
      <ErrorOutlineOutlined sx={{ fontSize: '48px', color: '#721c24' }} />
      <Typography variant="h5" align="center" sx={{ color: '#721c24', mt: 2 }}>
        Rechazada
      </Typography>
      <Typography
        variant="body2"
        align="center"
        sx={{ color: '#721c24', mt: 2 }}
        textAlign="justify"
      >
        Lamentablemente, su solicitud ha sido rechazada en este momento. Le recomendamos que se
        ponga en contacto con su representante comercial para explorar otras opciones de cuotas que
        puedan estar disponibles para usted.
      </Typography>
    </Box>
  );

  return <>{currentUser && (result === 'ACEPTAR' ? success : denied)}</>;
}
