import { useCallback, useState } from 'react';
import axios from 'axios';
import DocumentService from 'src/services/document';
import { getUuid } from 'src/utils/get-uuid';

const convertStringToArrayBuffer = (str) => {
  const textEncoder = new TextEncoder();
  return textEncoder.encode(str).buffer;
};

const convertFileToArrayBuffer = (file) =>
  new Promise((resolve, reject) => {
    if (!file || !file.name) {
      throw new Error('Invalid or missing file');
    }

    const reader = new FileReader();
    reader.onload = () => {
      const arrayBuffer = reader.result;
      if (arrayBuffer === null) {
        resolve(null);
        return;
      }
      if (typeof arrayBuffer === 'string') {
        resolve(convertStringToArrayBuffer(arrayBuffer));
        return;
      }
      if (!arrayBuffer) {
        throw new Error('Failed to read file into ArrayBuffer.');
      }
      resolve(arrayBuffer);
    };
    reader.onerror = () => {
      reject(new Error('Error reading file.'));
    };
    reader.readAsArrayBuffer(file);
  });

export default function UploadHandler(row, setUploadErrorMsg) {
  const { id } = row;
  const [files, setFiles] = useState([]);
  const uuid = getUuid();

  const setErrorMessage = useCallback(
    (message) => {
      setUploadErrorMsg(message);
    },
    [setUploadErrorMsg]
  );

  const handleDropMultiFile = useCallback(
    async (acceptedFiles) => {
      setErrorMessage('');

      let fileError = false;
      const regex = /^image\/.*$/;
      // eslint-disable-next-line radix
      const maxAmount = parseInt(process.env.REACT_APP_MAX_FILES_AMOUNT);
      // eslint-disable-next-line radix
      const maxSize = parseInt(process.env.REACT_APP_MAX_FILE_SIZE);
      if (acceptedFiles.length + files.length > maxAmount) {
        setErrorMessage(`No puede ingresar mas de ${maxAmount} archivos`);
        // eslint-disable-next-line no-useless-return
        return;
      }
      acceptedFiles.forEach((file) => {
        if (file.size > maxSize) {
          setErrorMessage(`El archivo debe pesar menos de ${maxSize / 1000000}MB`);
          fileError = true;
          // eslint-disable-next-line no-useless-return
          return;
        }
        if (file.type !== 'application/pdf' && !regex.test(file.type)) {
          setErrorMessage('El archivo debe estar en formato PDF o imagen');
          fileError = true;
          // eslint-disable-next-line no-useless-return
          return;
        }
      });
      if (fileError) {
        return;
      }

      setFiles([
        ...files,
        ...acceptedFiles.map((newFile) =>
          Object.assign(newFile, {
            preview: URL.createObjectURL(newFile),
          })
        ),
      ]);
    },
    [files, setErrorMessage]
  );

  const handleRemoveFile = (inputFile) => {
    setErrorMessage(null);
    const filesFiltered = files.filter((fileFiltered) => fileFiltered !== inputFile);
    setFiles(filesFiltered);
  };

  const handleRemoveAllFiles = () => {
    setErrorMessage(null);
    setFiles([]);
  };

  const handleUpload = async () => {
    const putUrlPromise = (fileIndex, extension) =>
      new Promise((resolve, reject) => {
        console.log('Pre llamada a DocumentService.uploadUrl con', uuid, id, fileIndex, extension);
        DocumentService.uploadUrl(uuid, id, fileIndex, extension)
          .then((url) => {
            console.log(
              'Respuesta de DocumentService.uploadUrl para',
              fileIndex,
              extension,
              ':',
              url
            );
            resolve(url);
          })
          .catch((err) => {
            console.error(
              'Error en DocumentService.uploadUrl para',
              fileIndex,
              extension,
              ':',
              err
            );
            reject(err);
          });
      });

    const UploadFilePromise = (url, file) =>
      new Promise((resolve, reject) => {
        if (!url) {
          const errorMessage =
            'Ha ocurrido un error inesperado, por favor, intentelo de nuevo más tarde';
          console.error('URL vacía o inválida en UploadFilePromise:', url);
          setErrorMessage(errorMessage);
          reject(new Error(errorMessage));
          return;
        }
        console.log('Llamada a DocumentService.uploadFile con url y file:', url, file);
        DocumentService.uploadFile(url, file)
          .then((uploadedInfo) => {
            console.log(
              'Respuesta de DocumentService.uploadFile para file:',
              file.name,
              uploadedInfo
            );
            resolve(uploadedInfo);
          })
          .catch((err) => {
            console.error('Error en DocumentService.uploadFile para file:', file.name, ':', err);
            if (err.response?.data.error) {
              setErrorMessage(err.response.data.error);
            } else {
              setErrorMessage(
                'Ha ocurrido un error inesperado, por favor, intentelo de nuevo más tarde'
              );
            }
            reject(err);
          });
      });

    try {
      const promises = files.map((file, index) => {
        const fileExt = file.type.split('/').pop();
        return putUrlPromise(index, fileExt);
      });

      const urlResponses = await Promise.all(promises);

      const uploadPromises = urlResponses.map((url, index) => {
        console.log('Preparando UploadFilePromise para url:', url, 'y file:', files[index]);
        return UploadFilePromise(url, files[index]);
      });

      await Promise.all(uploadPromises);
      console.log('Todos los archivos han sido subidos exitosamente');
    } catch (error) {
      console.error('Error durante la subida de archivos:', error);
    }
  };

  return {
    files,
    setFiles,
    handleDropMultiFile,
    handleRemoveFile,
    handleRemoveAllFiles,
    handleUpload,
  };
}
