import * as React from "react";
import {useState, useEffect} from 'react';
import {Link, useNavigate} from 'react-router-dom';

// @mui
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
// assets
import {SeoIllustration} from 'src/assets/illustrations';
// service
import {useAuthContext} from 'src/auth/hooks';
import ClientService from 'src/services/client';
import {errorAlert} from 'src/utils/error-dialog';
// utils
import {getUuid} from 'src/utils/get-uuid';
// components
import {useRouter} from 'src/routes/hooks';
import {useSettingsContext} from '../../../components/settings';
import DialogBankSelector from "./dialog-bank-account";

// ----------------------------------------------------------------------
export default function UnnaxView() {
  const settings = useSettingsContext();
  const navigate = useNavigate();

  const [solution, setSolution] = useState(null);
  const [loanding, setLoanding] = useState(true);
  const router = useRouter();
  const [openDialogUnnax, setOpenDialogUnnax] = useState(false);

  // error handling
  const [errorMsg, setErrorMsg] = useState('');
  const [errorTitle, setErrorTitle] = useState('');
  const [openDialog, setOpenDialog] = useState(false);

  const handleCloseBank = (value) => {
    setOpenDialogUnnax(false);
  };

  const uuid = getUuid();


  return (
    <>
      <SeoIllustration sx={{height: 200}}/>
      <Stack spacing={1} sx={{my: 2}} textAlign="center" alignItems="center">
        <Typography variant="h3">Agregar cuenta bancaria</Typography>
        <Typography variant="body2" sx={{color: 'text.secondary'}}>
          Este widget nos permite acceder a sus registros bancarios de manera segura y consolidar
          toda la información financiera en una sola interfaz, facilitando la gestión y el análisis
          de sus cuentas.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          sx={{width: '50%'}}
          onClick={() => {
            // handleConnectBankAccount();
            setOpenDialogUnnax(true)
          }}
        >
          Conecta tu cuenta bancaria
        </Button>
      </Stack>

      <DialogBankSelector
        open={openDialogUnnax}
        onClose={handleCloseBank}
        setOpenBank={setOpenDialogUnnax}
      />


      {/* <AppWidgetUnnax open={openDialogUnnax} onClose={() => setOpenDialogUnnax(false)}/> */}
      <Stack sx={{textAlign: 'left'}} direction={{xs: 'column', sm: 'row'}} spacing={1}>
        <Button component={Link} to={`/clients/welcome/${uuid}`} variant="soft">
          Volver atrás
        </Button>
      </Stack>
    </>
  );
}
