/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import NumericFormatCustom from 'src/utils/money-input-format';
import { encryptdata } from 'src/utils/encryption';
// @mui
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { Button, InputLabel, MenuItem, Select, InputAdornment } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import FormLabel from '@mui/material/FormLabel';

// services
import ApplicationService from 'src/services/application';

// components
import { useSettingsContext } from 'src/components/settings';
import PaymentMethods from 'src/components/payment/payment-methods';
import PaymentBillingAddress from 'src/components/payment/payment-billing-address';
// Hooks
import { useAuthContext } from 'src/auth/hooks';
import { fCurrency } from 'src/utils/format-number';
import ErrorView, { errorAlert } from 'src/utils/error-dialog';
import ClientService from 'src/services/client';
import FormProvider from 'src/components/hook-form/form-provider';
import { yupResolver } from '@hookform/resolvers/yup';
import { RHFTextField } from 'src/components/hook-form';
// utils
import { getUuid } from 'src/utils/get-uuid';
import { paths } from 'src/routes/paths';
// icons
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import {useRouter} from "../../routes/hooks";
// ----------------------------------------------------------------------

export default function PaymentPage() {
  const settings = useSettingsContext();

  const [amount, setAmount] = useState(0);
  const [contract, setContract] = useState();

  const { user } = useAuthContext();
  const router = useRouter();

  const [cvv, setCvv] = useState('');
  const [expireDate, setExpireDate] = useState('');
  const [cardNumber, setCardNumber] = useState('');

  const [openDialog, setOpenDialog] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [errorTitle, setErrorTitle] = useState('');
  const [closeFunction, setCloseFunction] = useState();

  const [payError, setPayError] = useState('');

  const [billingData, setBillingData] = useState({});

  const [currentUser, setCurrentUser] = useState({});
  const [isSent, setSent] = useState(false);

  const uuid = getUuid();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await ApplicationService.get(uuid);
        setCurrentUser(response);
      } catch (error) {
        errorAlert(
          'Ocurrio un error inesperado',
          'Ha ocurrido un error inesperado al cargar la pagina',
          setErrorMsg,
          setErrorTitle,
          setOpenDialog
        );
        console.error('Error fetching client info:', error);
      }
    };

    fetchData();
    // reRender is here to ensure the useEffect executing when the component re-renders
  }, [uuid]);

  const defaultValues = {
    amount: '',
    holder: '',
    expireDate: '',
    cvv: '',
  };

  const AmountSchema = Yup.object().shape({
    amount: Yup.number()
      .required('El importe a pagar es requerido')
      .typeError('El importe debe ser un número')
      .min(1, 'El importe debe ser mayor a 0')
      .max(contract?.amount, `El importe debe ser menor o igual a ${fCurrency(contract?.amount)}`),
  });
  const CardSchema = Yup.object().shape({
    cardNumber: Yup.number()
      .required('Ingrese el numero de la tarjeta')
      .typeError('Numero de tarjeta invalido')
      .test('is-valid-card', 'Numero de tarjeta invalido', (value) => !(payError === 'cardNumber')),
    holder: Yup.string()
      .required('Ingrese el Nombre del titular')
      .test('is-valid-holder', 'Nombre del titular invalido', (value) => !(payError === 'holder')),
    exipreDate: Yup.string()
      .required('Ingrese la fecha de vencimiento')
      .matches(/^(0[1-9]|1[0-2])\/\d{2}$/, 'Ingrese la fecha en el formato dd/mm')
      .test(
        'is-valid-date',
        'Fecha de expiracion invalida',
        (value) => !(payError === 'expireDate')
      ),
    cvv: Yup.number()
      .required('Ingrese el código de seguridad de la tarjeta')
      .typeError('Código de seguridad invalido')
      .test('is-valid-cvv', 'Código de seguridad invalido', (value) => !(payError === 'cvv')),
  });

  const combinedSchema = Yup.object().shape({
    ...AmountSchema.fields,
    ...CardSchema.fields,
  });

  const methods = useForm({
    resolver: yupResolver(combinedSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const sendPayment = handleSubmit(async (paymentData) => {
    console.log(paymentData);
    console.log(typeof amount);
    if (amount <= 0 || typeof amount !== 'number') {
      setCloseFunction(() => () => {
        setOpenDialog(false);
      });
      errorAlert(
        'Importe invalido',
        'El importe debe ser mayor a 0',
        setErrorMsg,
        setErrorTitle,
        setOpenDialog
      );
      return;
    }
    try {
      const data = encryptdata({
        holderName: paymentData.holder,
        cvv: paymentData.cvv,
        expireDate: paymentData.expireDate,
        cardNumber: paymentData.cardNumber,
        // amount: amount,
        // billingData: billingData,
      });
      //   const response = await ClientService.payment({ data: data });
    } catch (error) {
      console.log(error);

      if (error.response.data.error === 'unexpected' || error.response.status !== 400) {
        setCloseFunction(() => () => {
          setOpenDialog(false);
        });
        errorAlert(
          'Ocurrio un error inesperado',
          'Ha ocurrido un error al intentar hacer el pago. Por favor, intentelo de nuevo mas tarde',
          setErrorMsg,
          setErrorTitle,
          setOpenDialog
        );
      } else {
        console.log('asd');
        setPayError(error.response.data.error);
      }
    }
  });

  const onAmountChange = (e) => {
    setAmount(Number(e));
  };

  const [method, setMethod] = React.useState('tarjeta');

  const handleChange = (event) => {
    setMethod(event.target.value);
    console.log('Método:', method);
  };
  const renderPaymentMethod = (

      <Card sx={{mb: 3}}>
        <CardContent>
          <FormControl sx={{ display: 'flex', flexDirection: 'column' }}>
            <FormLabel id="demo-radio-buttons-group-label" sx={{ alignSelf: 'center' }}>
              Método de pago
            </FormLabel>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              name="radio-buttons-group"
              value={method}
              onChange={handleChange}
              defaultValue="tarjeta"
              sx={{ display: 'flex', flexDirection: 'column' }}
            >
              <Stack direction="row" spacing={2} justifyContent="center">
                <Stack direction="row" alignItems="center" spacing={0.5} justifyContent="space-between">
                <FormControlLabel value="cuenta" control={<Radio />} label="Cuenta bancaria" />
                <AccountBalanceOutlinedIcon fontSize="small" />
              </Stack>
              <Stack direction="row" alignItems="center" spacing={0.5} justifyContent="space-between">
                <FormControlLabel value="tarjeta" control={<Radio />} label="Tarjeta de crédito" />
                <CreditCardOutlinedIcon fontSize="small" color="#637381" />
              </Stack>
              </Stack>

            </RadioGroup>
          </FormControl>
        </CardContent>
      </Card>

  );

  const renderCircularProgress = (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        mb: '1rem',
      }}
    >
      <CircularProgress size={30} />
      <Typography variant="h6" align="center" sx={{ ml: '10px', mt: '1rem' }}>
        Su solicitud está siendo gestionada
      </Typography>
    </Box>
  );
  const renderFinalMessage = (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        m: 0,
      }}
    >
      <Typography variant="h6" align="center" sx={{ m: 0 }}>
        Su solicitud ha sido enviada.
      </Typography>

      <Button variant="outlined" sx={{margin: 2}} onClick={() => router.push(paths.clients.landing(uuid))}>
        Mi situación financiera
      </Button>
    </Box>
  );

  // loading button
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = () => {
    setIsLoading(true);

    setTimeout(() => {
      setIsLoading(false);
      setSent(true);
    }, 4000);
  };

  const onSubmit = handleSubmit(async (paymentDetails) => {
    console.log('Se ha llamado a onSubmit de finalPayment');
    // TODO añadir llamada a la base de datos cargando datos
  });

  return (
    <Container
      maxWidth={settings.themeStretch ? false : 'xl'}
      sx={{
        minHeight: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      {!isSent ? (
        isLoading ? (
          renderCircularProgress
        ) : (
          <>
            <Typography variant="h3" align="center" sx={{ mb: 2 }}>
              Método de pago
            </Typography>
            <Typography variant="body2" align="center" sx={{ mb: 2 }}>
              Por favor, indícanos donde quieres que carguemos la mensualidad
            </Typography>
            {renderPaymentMethod}

            <Grid>
              <FormProvider
                style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}
                methods={methods}
                onSubmit={onSubmit}
              >
                <PaymentBillingAddress
                  user={currentUser}
                  setData={setBillingData}
                  data={billingData}
                />

                {method === 'tarjeta' ? (
                  <PaymentMethods
                    setCvv={setCvv}
                    setExpireDate={setExpireDate}
                    setCardNumber={setCardNumber}
                    cvv={cvv}
                    expireDate={expireDate}
                    cardNumber={cardNumber}
                  />
                ) : (
                  <Box
                    sx={{
                      // p: 5,
                      pl: 3,
                      borderRadius: 2,
                      width: '50%',
                    }}
                  >
                    <Typography variant="h6" sx={{ mb: 2 }}>
                      Introduzca su IBAN
                    </Typography>
                    <RHFTextField
                      name="IBAN"
                      label="IBAN"
                      sx={{ minWidth: '14em' }}
                      placeholder="ES 1234 1234 1234 "
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AccountBalanceOutlinedIcon fontSize="small" />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                )}
              </FormProvider>
              <Box sx={{ justifyContent: 'end', display: 'flex', marginTop: 2 }}>
                <Button variant="contained" onClick={handleClick} disabled={isLoading}>
                  {isLoading ? <CircularProgress size={24} /> : 'Finalizar'}
                </Button>
              </Box>
            </Grid>
            <ErrorView
              openDialog={openDialog}
              setOpenDialog={setOpenDialog}
              errorMsg={errorMsg}
              errorTitle={errorTitle}
              closeFunction={closeFunction}
            />
          </>
        )
      ) : (
        renderFinalMessage
      )}
    </Container>
  );
}
