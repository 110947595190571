import React from 'react';
import { useLocation } from 'react-router-dom';
// mui
import {
  CheckCircle,
  Assignment,
  Receipt,
  Payment,
  Description,
  CloudUpload,
  AccountBalance,
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';

import FileIcon from '@mui/icons-material/AttachFile';
import { Stepper, Step, StepLabel, StepConnector, Typography } from '@mui/material';
// utils
import { getUuid } from 'src/utils/get-uuid';

const useStyles = styled((theme) => ({
  step_label_root: {
    fontSize: '10px',
  },
}));
const stepNames = ['Bienvenido', 'Método'];

const stepIcons = [<Assignment />, <Receipt />, <CheckCircle />, <Payment />];

const stepTwoOptions = [
  { label: 'Cuestionario', icon: <Description /> },
  { label: 'Documentación', icon: <FileIcon /> },
  { label: 'Documentación', icon: <CloudUpload /> },
  { label: 'Agregación Bancaria', icon: <AccountBalance /> },
];

const uuid = getUuid();

const getCurrentStep = (pathname) => {
  switch (pathname) {
    case `/clients/welcome/${uuid}`:
      return { index: 0, label: stepNames[0], icon: stepIcons[0] };
    case `/clients/questionnaire/${uuid}`:
      return { index: 1, label: stepTwoOptions[1].label, icon: stepTwoOptions[0].icon };
    case `/clients/files/${uuid}`:
      return { index: 1, label: stepTwoOptions[1].label, icon: stepTwoOptions[1].icon };
    case `/clients/files/upload/autonomous/${uuid}`:
      return { index: 1, label: stepTwoOptions[1].label, icon: stepTwoOptions[2].icon };
    case `/clients/files/upload/salaried/${uuid}`:
      return { index: 1, label: stepTwoOptions[1].label, icon: stepTwoOptions[2].icon };
    case `/clients/bank/${uuid}`:
      return { index: 1, label: stepTwoOptions[1].label, icon: stepTwoOptions[3].icon };
    case `/clients/result/${uuid}`:
      return { index: 1, label: stepNames[1], icon: stepIcons[2] };
    case `/clients/payment/${uuid}`:
      return { index: 3, label: stepNames[1], icon: stepIcons[3] };
    default:
      return { index: 0, label: stepNames[0], icon: stepIcons[0] };
  }
};

const CustomStepper = () => {
  const { pathname } = useLocation();
  const { index, label, icon } = getCurrentStep(pathname);

  return (
    <Stepper activeStep={index} sx={{ py: 3 }}>
      {stepNames.map((_, i) => (
        <Step key={i}>
          <StepLabel
            // icon={i === index ? icon : null}
            sx={{ '& .MuiStepLabel-label.MuiStepLabel-alternativeLabel': { margin: 0 } }}
          />
          {/* {i === index ? label : null} */}
          {/* </StepLabel> */}
        </Step>
      ))}
    </Stepper>
  );
};

export default CustomStepper;
