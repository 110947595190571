import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { styled } from '@mui/material/styles';
import { Stack } from '@mui/system';
import Typography from '@mui/material/Typography';

const StyledCard = styled(Card)(({ theme: { breakpoints, spacing } }) => ({
  // width: 360,
  maxWidth: '100%',
  minHeight: '230px',
  // 16px
  borderRadius: spacing(2),
  transition: '0.3s',
  boxShadow: '0px 14px 80px rgba(34, 35, 58, 0.2)',
  position: 'relative',
  overflow: 'initial',
  display: 'flex',
  flexDirection: 'column',
  paddingLeft: 1,
  paddingRight: 1,
  background:
    'linear-gradient(34deg, rgba(181,0,12,1) 0%, rgba(255,48,59,1) 29%, rgba(181,0,12,1) 92%)',
  [breakpoints.up('sm')]: {
    textAlign: 'left',
    flexDirection: 'row-reverse',
  },
}));

const TypographyOverline = styled(Typography)(() => ({
  // lineHeight: 0,
  color: 'rgba(19, 53, 235,0.6)',
  fontWeight: 'bold',
  fontSize: '1.3rem',
  opacity: 0.7,
}));

const TypographyHeading = styled(Typography)(() => ({
  fontWeight: 900,
  color: 'rgba(19, 53, 235,0.6)',
  letterSpacing: 0.5,
}));

const StyledButton = styled(Button)(({ theme: { breakpoints } }) => ({
  backgroundColor: 'rgba(255, 255, 255, 0.35)',
  borderRadius: 100,
  marginTop: 20,
  paddingLeft: 32,
  paddingRight: 32,
  color: 'rgba(19, 53, 235,0.6)',
  textTransform: 'none',
  width: '100%',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.32)',
  },
  [breakpoints.up('sm')]: {
    width: 'auto',
  },
}));

export function BannerLanding({ headerText, text, buttons, color, textColor, align }) {
  return (
    <StyledCard
      sx={{ background: color, ...(align && { alignItems: align }), justifyContent: 'flex-end' }}
    >
      <CardContent>
        <TypographyHeading
          variant="h3"
          gutterBottom
          sx={{ ...(textColor && { color: textColor }) }}
        >
          {headerText}
        </TypographyHeading>
        <TypographyOverline sx={{ ...(textColor && { color: textColor }) }}>
          {text}
        </TypographyOverline>
        {buttons && (
          <Stack direction="row" spacing={2}>
            {buttons.map((button) => (
              <StyledButton
                size="large"
                onClick={button.onClick}
                sx={{ ...(textColor && { color: textColor }) }}
              >
                {button.text}
              </StyledButton>
            ))}
          </Stack>
        )}
      </CardContent>
    </StyledCard>
  );
}

BannerLanding.propTypes = {
  headerText: PropTypes.string,
  text: PropTypes.string,
  buttons: PropTypes.array,
  color: PropTypes.string,
  textColor: PropTypes.string,
  align: PropTypes.string,
};
