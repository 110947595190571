import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Typography from '@mui/material/Typography';
import { PATH_AGREEMENTS } from 'src/config-global';
import { useSettingsContext } from 'src/components/settings';
import { useRouter } from 'src/routes/hooks';

export default function AppSnackbarComponent({ text, color = 'warning' }) {
  const settings = useSettingsContext();
  const router = useRouter();

  const [open, setOpen] = useState(settings.refinance && !settings.finished);
  return (
    <>
      {settings.refinance && !settings.finished && (
        <Snackbar
          open={open}
          onClose={() => setOpen(false)}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          style={{ width: '40%' }}
        >
          <Alert onClose={() => setOpen(false)} severity={color} sx={{ width: '100%' }}>
            <Typography variant="h5" onClick={() => router.push(PATH_AGREEMENTS)}>
              Tienes pasos pendientes. ¡Recuerda completarlos para solicitar tu acuerdo!
            </Typography>
          </Alert>
        </Snackbar>
      )}
    </>
  );
}

AppSnackbarComponent.propTypes = {
  text: PropTypes.string,
  color: PropTypes.string,
};
