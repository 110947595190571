import PropTypes from 'prop-types';
import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';

const BankContext = createContext();

export const useBank = () => useContext(BankContext);

export const BankProvider = ({ children }) => {
    const [bankAggregation,setBankAggregation] = useState(false)

    


  const value = useMemo(() => ({bankAggregation,setBankAggregation}),[bankAggregation,setBankAggregation]);

  return <BankContext.Provider value={value}>{children}</BankContext.Provider>;
};

BankProvider.propTypes = {
  children: PropTypes.node,
};
