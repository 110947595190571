import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
// @mui
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Iconify from 'src/components/iconify';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { Alert } from '@mui/material';

//
import LoadingSpinner from '../loading/loading';
import RejectionFiles from './errors-rejection-files';
import MultiFilePreview from './preview-multi-file';
import SingleFilePreview from './preview-single-file';
// assets
import { UploadIllustration } from '../../assets/illustrations';

// ----------------------------------------------------------------------

export default function Upload({
  open,
  onClose,
  title,
  loading,
  disabled,
  multiple = false,
  error,
  helperText,
  //
  file,
  onDelete,
  //
  files,
  thumbnail,
  onUpload,
  onRemove,
  onRemoveAll,
  onDrop,
  sx,
  errorMsg,
  ...other
}) {
  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    multiple,
    disabled,
    onDrop,
    ...other,
  });

  const hasFile = !!file && !multiple;

  const hasFiles = !!files && multiple && !!files.length;

  const hasError = isDragReject || !!error;

  const renderPlaceholder = (
    <Stack spacing={3} alignItems="center" justifyContent="center" flexWrap="wrap">
      <UploadIllustration sx={{ width: 1, maxWidth: 200 }} />
      <Stack spacing={1} sx={{ textAlign: 'center' }}>
        <Typography variant="h6">Seleccionar archivo o arrastrar</Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          Arrastre los archivos aquí o haga clic
          <Box
            component="span"
            sx={{
              mx: 0.5,
              color: 'primary.main',
              textDecoration: 'underline',
            }}
          >
            aquí
          </Box>
          para seleccionarlos desde su dispositivo.
        </Typography>
      </Stack>
    </Stack>
  );

  const renderSinglePreview = (
    <SingleFilePreview imgUrl={typeof file === 'string' ? file : file?.preview} />
  );

  const removeSinglePreview = hasFile && onDelete && (
    <IconButton
      size="small"
      onClick={onDelete}
      sx={{
        top: 16,
        right: 16,
        zIndex: 9,
        position: 'absolute',
        color: (theme) => alpha(theme.palette.common.white, 0.8),
        bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
        '&:hover': {
          bgcolor: (theme) => alpha(theme.palette.grey[900], 0.48),
        },
      }}
    >
      <Iconify icon="mingcute:close-line" width={18} />
    </IconButton>
  );

  const renderMultiPreview = hasFiles && (
    <>
      <Box sx={{ my: 3 }}>
        <MultiFilePreview files={files} thumbnail={thumbnail} onRemove={onRemove} />
      </Box>

      <Stack direction="row" justifyContent="flex-end" spacing={1.5}>
        {onRemoveAll && (
          <Button color="inherit" variant="outlined" size="small" onClick={onRemoveAll}>
            Eliminar todos
          </Button>
        )}

        {onUpload && (
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={onUpload}
            startIcon={<Iconify icon="eva:cloud-upload-fill" />}
          >
            Cargar
          </Button>
        )}
      </Stack>
    </>
  );
  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={loading ? null : () => onClose()}>
      <DialogTitle sx={{ p: (theme) => theme.spacing(3, 3, 2, 3) }}> {title} </DialogTitle>
      <DialogContent dividers sx={{ pt: 0, pb: 3, border: 'none' }}>
        <LoadingSpinner loading={loading} />
        <Box sx={{ width: 1, position: 'relative', ...sx }}>
          <Box
            {...getRootProps()}
            sx={{
              p: 5,
              outline: 'none',
              borderRadius: 1,
              cursor: 'pointer',
              overflow: 'hidden',
              position: 'relative',
              bgcolor: (theme) => alpha(theme.palette.grey[500], 0.08),
              border: (theme) => `1px dashed ${alpha(theme.palette.grey[500], 0.2)}`,
              transition: (theme) => theme.transitions.create(['opacity', 'padding']),
              '&:hover': {
                opacity: 0.72,
              },
              ...(isDragActive && {
                opacity: 0.72,
              }),
              ...(disabled && {
                opacity: 0.48,
                pointerEvents: 'none',
              }),
              ...(hasError && {
                color: 'error.main',
                borderColor: 'error.main',
                bgcolor: (theme) => alpha(theme.palette.error.main, 0.08),
              }),
              ...(hasFile && {
                padding: '24% 0',
              }),
            }}
          >
            <input {...getInputProps()} />

            {hasFile ? renderSinglePreview : renderPlaceholder}
          </Box>
          {errorMsg && (
            <Alert sx={{ marginTop: 1 }} severity="error">
              {errorMsg}
            </Alert>
          )}

          {removeSinglePreview}

          {helperText && helperText}

          <RejectionFiles fileRejections={fileRejections} />

          {renderMultiPreview}
        </Box>
      </DialogContent>
    </Dialog>
  );
}

Upload.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.object,
  error: PropTypes.bool,
  file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  files: PropTypes.array,
  helperText: PropTypes.object,
  multiple: PropTypes.bool,
  onDelete: PropTypes.func,
  onRemove: PropTypes.func,
  onRemoveAll: PropTypes.func,
  onUpload: PropTypes.func,
  onDrop: PropTypes.func,
  sx: PropTypes.object,
  thumbnail: PropTypes.bool,
  errorMsg: PropTypes.string,
};
