export function getUuid() {
  const url = window.location.href;
  let uuid = '';
  const parts = url.split('/');
  if (parts.includes('upload')) {
    uuid = parts[7];
  } else {
    uuid = parts[5];
  }
  return uuid;
}
