import PropTypes from 'prop-types';
import { formatDistanceToNowStrict } from 'date-fns';
import esLocale from 'date-fns/locale/es';
// @mui
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
// components
import Iconify from 'src/components/iconify';
// routes
import { useRouter } from 'src/routes/hooks';

// ----------------------------------------------------------------------

// displays the details of a message
export default function ChatMessageItem({ message, reciever, user, onOpenLightbox }) {
  const me =
    user &&
    ((user.role === 'agent' && message.sent_by === 'A') ||
      message.sent_by === 'C' ||
      (user.role === 'admin' && message.sent_by === 'A'));

  const senderDetails = me ? user : reciever;
  const { name, avatarUrl } = senderDetails;
  const { sent } = message;

  const renderInfo = (
    <Typography
      noWrap
      variant="caption"
      sx={{
        mb: 1,
        color: 'text.disabled',
        ...(!me && {
          mr: 'auto',
        }),
      }}
    >
      {!me && `${name},`} &nbsp;
      {formatDistanceToNowStrict(new Date(sent), {
        addSuffix: true,
        locale: esLocale,
      })}
    </Typography>
  );

  const renderBody = (
    <Stack
      sx={{
        p: 1.5,
        minWidth: 48,
        maxWidth: user && user.role === 'client' ? 220 : 350,
        borderRadius: 1,
        typography: 'body2',
        bgcolor: 'background.neutral',
        ...(me && {
          color: 'grey.800',
          bgcolor: 'primary.lighter',
        }),
        wordWrap: 'break-word',
      }}
    >
      {message.message}
    </Stack>
  );

  const renderActions = (
    <Stack
      direction="row"
      className="message-actions"
      sx={{
        pt: 0.5,
        opacity: 0,
        top: '100%',
        left: 0,
        position: 'absolute',
        transition: (theme) =>
          theme.transitions.create(['opacity'], {
            duration: theme.transitions.duration.shorter,
          }),
        ...(me && {
          left: 'unset',
          right: 0,
        }),
      }}
    >
      {/* Puedes agregar los botones de acción aquí */}
    </Stack>
  );

  const renderError =
    message.status === 'error' ? (
      <Typography
        fontSize={user.role === 'client' ? 12 : 14}
        color="error"
        style={{
          display: 'flex',
          alignItems: 'center',
          textAlign: user.role === 'client' ? 'end' : null,
        }}
      >
        <Iconify
          icon="material-symbols:error-outline"
          width={user.role === 'client' ? 26 : 16}
          marginRight={1}
        />
        Hubo un error al enviar su mensaje, por favor intentelo de nuevo
      </Typography>
    ) : null;

  return (
    <Stack direction="row" justifyContent={me ? 'flex-end' : 'unset'} sx={{ mb: 3 }}>
      {!me && (
        <Avatar
          alt={name}
          src={
            avatarUrl || 'https://api-dev-minimal-v510.vercel.app/assets/images/avatar/avatar_2.jpg'
          }
          sx={{ width: 32, height: 32, mr: 2 }}
        />
      )}

      <Stack alignItems={me ? 'flex-end' : 'flex-start'}>
        {renderInfo}

        <Stack
          direction="row"
          alignItems="center"
          sx={{
            position: 'relative',
            '&:hover': {
              '& .message-actions': {
                opacity: 1,
              },
            },
          }}
        >
          {renderBody}
        </Stack>
        {renderError}
      </Stack>
    </Stack>
  );
}

ChatMessageItem.propTypes = {
  message: PropTypes.object,
  onOpenLightbox: PropTypes.func,
  reciever: PropTypes.object,
  user: PropTypes.object,
};
