import PropTypes from 'prop-types';
// @mui
import Box from '@mui/material/Box';
// components
import Scrollbar from 'src/components/scrollbar';
import Lightbox, { useLightBox } from 'src/components/lightbox';
import EmptyContent from 'src/components/empty-content';
//
import ChatService from 'src/services/chat';
import { useAuthContext } from 'src/auth/hooks';
import { useRouter } from 'src/routes/hooks';
import { useMessagesScroll } from './hooks';
import ChatMessageItem from './chat-message-item';
// ----------------------------------------------------------------------

// takes the list of messages and maps them in a component
export default function ChatMessageList({ messages = [], reciever, chatID, user }) {
  const { messagesEndRef } = useMessagesScroll(messages);
  const router = useRouter();
  const { logout } = useAuthContext();

  const slides = messages
    .filter((message) => message.contentType === 'image')
    .map((message) => ({ src: message.body }));

  const unread = [];
  const lightbox = useLightBox(slides);

  return (
    <>
      <Scrollbar ref={messagesEndRef} sx={{ px: 3, py: 5, height: 1 }}>
        <Box>
          {messages.length > 0 ? (
            messages.map((message, index) => {
              if (message.read === false && message.sent_by === reciever.role) {
                unread.push(message);
              }
              if (index === messages.length - 1) {
                ChatService.markAsRead({ messages: unread, chatID }).catch((error) => {
                  console.log('failed marking messages');
                });
              }
              return (
                <ChatMessageItem
                  key={index}
                  message={message}
                  reciever={reciever}
                  onOpenLightbox={() => lightbox.onOpen(message.body)}
                  user={user}
                />
              );
            })
          ) : (
            <EmptyContent title="No hay mensajes" />
          )}
        </Box>
      </Scrollbar>

      <Lightbox
        index={lightbox.selected}
        slides={slides}
        open={lightbox.open}
        close={lightbox.onClose}
      />
    </>
  );
}

ChatMessageList.propTypes = {
  messages: PropTypes.array,
  reciever: PropTypes.object,
  chatID: PropTypes.string,
  user: PropTypes.object,
};
