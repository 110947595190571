import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import FormProvider from 'src/components/hook-form';

import { InputForm, SelectForm, RadioGroupForm, MultiSelectForm } from './components';
// ----------------------------------------------------------------------
export default function FormKYC({
  activeStep,
  questions,
  onChange,
  onNext,
  onSubmit,
  defaultValues,
}) {
  const methods = useForm({
    mode: 'onChange',
    defaultValues,
  });

  const renderFormItem = (item) => (
    <Fragment key={item.id}>
      <Typography variant="body2">{item.name}</Typography>
      {(item.type === 'input' || item.type === 'money') && (
        <InputForm item={item} onChange={onChange} onNext={onNext} />
      )}
      {item.type === 'select' && <SelectForm item={item} onChange={onChange} onNext={onNext} />}
      {item.type === 'multiselect' && (
        <MultiSelectForm item={item} onChange={onChange} onNext={onNext} />
      )}
      {item.type === 'radio_group' && (
        <RadioGroupForm item={item} onChange={onChange} onNext={onNext} />
      )}
    </Fragment>
  );

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      {questions.map(
        (item, index) =>
          activeStep === item.step && (
            <Stack spacing={1} textAlign="justify">
              {item.parent_id === null && renderFormItem(item)}
              {(index === 0 || index === 7) && <Divider sx={{ mt: 1, mb: 2 }} />}
              <Stack spacing={1} sx={{ mx: 3 }}>
                {questions
                  .filter((question) => question.parent_id === item.id)
                  .map(
                    (children, i) =>
                      children.condition.includes(item.value) && renderFormItem(children)
                  )}
              </Stack>
            </Stack>
          )
      )}
    </FormProvider>
  );
}

FormKYC.propTypes = {
  activeStep: PropTypes.number,
  questions: PropTypes.array,
  onChange: PropTypes.func,
  onNext: PropTypes.func,
  onSubmit: PropTypes.func,
  defaultValues: PropTypes.object,
};
