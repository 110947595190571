import axios, { endpoints } from 'src/utils/axios';
import { setSession } from 'src/auth/context/utils';

const interceptorCorrect = (response) => response;
// eslint-disable-next-line consistent-return
const interceptorError = async (error) => {
  console.log('ERROOOOOOOR', error);
  if (error.response?.status === 402 || error.response?.status === 401) {
    sessionStorage.setItem('redirected', true);
    sessionStorage.setItem('redirectMessage', error.response?.data.error);
    sessionStorage.setItem('redirectSeverity', 'error');
    console.log('ERROR 401');
    await setSession();
    /* eslint-disable no-restricted-globals */
    location.reload();
  } else if (error.response?.status === 501) {
    console.log('ERROR 501');
  } else {
    return Promise.reject(error);
  }
};

class ApplicationService {
  static axiosInstance;

  static createAxios = () => {
    ApplicationService.axiosInstance = axios.create({
      withCredentials: true,
      defaults: {
        headers: axios.defaults.headers,
      },
    });

    ApplicationService.axiosInstance.interceptors.response.use(
      interceptorCorrect,
      interceptorError
    );

    return ApplicationService.axiosInstance;
  };

  static async sendForm(data) {
    const axiosInstance = this.createAxios();
    const endpoint = endpoints.api.agent.form;
    const response = await axiosInstance.post(endpoint, data);
  }

  static async updateForm(uuid, data) {
    const axiosInstance = this.createAxios();
    const endpoint = endpoints.api.agent.editForm(uuid);
    const response = await axiosInstance.put(endpoint, data);
  }

  static async getForms(config) {
    const axiosInstance = this.createAxios();
    const endpoint = endpoints.api.agent.list;
    const response = await axiosInstance.get(endpoint, config);
    return response;
  }

  static async get(nif) {
    const axiosInstance = this.createAxios();
    const endpoint = endpoints.api.agent.get(nif);
    const response = await axiosInstance.get(endpoint);

    return response.data;
  }
}

export default ApplicationService;
