const icons = {
  1: 'solar:check-circle-outline',
  2: 'mdi:account-file-text',
  3: 'mdi:file-document-outline',
  4: 'mdi:clipboard-text-outline',
  5: 'mdi:bank-transfer',
  6: 'mdi:file-sign',
};

const steps = [
  { id: 1, title: 'Inicio de la solicitud', description: '', action: null },
  {
    id: 2,
    title: 'Completar información',
    description: 'Cuestionario y/o Ag. Bancaria',
    action: 'completeInfo',
  },
  { id: 3, title: 'En estudio', description: 'Solicitud preaprobada o denegada', action: null },
  { id: 4, title: 'Subir documentación', description: 'Cargar documentos', action: 'uploadDocs' },
  { id: 5, title: 'En estudio', description: 'Solicitud aprobada o rechazada', action: null },
  { id: 6, title: 'Firmar operación', description: 'Firma completada', action: 'signContract' },
];

const stepLabels = {
  1: 'Inicio solicitud',
  2: {
    default: 'Completar información',
    questionnaire: 'Cuestionario rellenado',
    bank: 'Información bancaria facilitada',
  },
  3: {
    default: 'En estudio',
    questionnaire: {
      DENEGAR: 'Solicitud rechazada',
      default: 'Solicitud preaprobada',
    },
    bank: 'Solicitud preaprobada',
  },
  4: {
    default: 'Subir documentación',
    documents: 'Documentación cumplimentada',
  },
  5: {
    default: 'Solicitud aprobada',
    documents: 'En estudio',
  },
  6: {
    default: 'Firmar operación',
    documents: 'Operación firmada',
  },
};

const stepsRejected = [
  { id: 1, title: 'Inicio de la solicitud', description: '', action: null },
  {
    id: 2,
    title: 'Completar información',
    description: 'Cuestionario y/o Ag. Bancaria',
    action: 'completeInfo',
  },
  { id: 3, title: 'En estudio', description: 'Solicitud rechazada', action: null },
];

const stepLabelsRejected = {
  1: 'Inicio solicitud',
  2: {
    default: 'Completar información',
    questionnaire: 'Cuestionario rellenado',
    bank: 'Información bancaria facilitada',
  },
  3: {
    default: 'Solicitud rechazada',
  },
};

export { icons, steps, stepLabels, stepsRejected, stepLabelsRejected };
