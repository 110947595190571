/* eslint-disable class-methods-use-this */
import axios, { endpoints } from 'src/utils/axios';
import { setSession } from 'src/auth/context/utils';

const interceptorCorrect = (response) => response;
// eslint-disable-next-line consistent-return
const interceptorError = async (error) => {
  console.log('ERROOOOOOOR', error);
  if (error.response?.status === 402 || error.response?.status === 401) {
    sessionStorage.setItem('redirected', true);
    sessionStorage.setItem('redirectMessage', error.response.data.error);
    sessionStorage.setItem('redirectSeverity', 'error');
    console.log('ERROR 401');
    await setSession();
    /* eslint-disable no-restricted-globals */
    location.reload();
  } else if (error.response?.status === 501) {
    console.log('ERROR 501');
  } else {
    return Promise.reject(error);
  }
};

class DocumentService {
  static axiosInstance;

  static createAxios = () => {
    DocumentService.axiosInstance = axios.create({
      defaults: {
        headers: axios.defaults.headers,
      },
    });

    DocumentService.axiosInstance.interceptors.response.use(interceptorCorrect, interceptorError);

    return DocumentService.axiosInstance;
  };

  static downloadUrls = (expedient_id, id) => {
    const axiosInstance = this.createAxios();

    return axiosInstance.post(endpoints.api.documentUrl.get, { expedient_id, id });
  };

  static uploadUrl = (uuid, document, fileIndex, extension) => {
    const axiosInstance = this.createAxios();
    console.log('Se llama a  DocumentService.uploadUrl con', uuid, document, fileIndex, extension);
    console.log('A endpoint', endpoints.api.documentUrl.put);

    return axiosInstance.post(endpoints.api.documentUrl.put, {
      client: uuid,
      document,
      fileIndex,
      extension,
    });
  };

  static uploadFile = (url, file) => {
    const axiosInstance = this.createAxios();
    console.log('Se llama a UploadFile con url y file:', url.data.url, file);
    const options = {
      responseType: 'blob',
      headers: {
        'Content-Type': file.type,
        'Access-Control-Allow-Origin': '*',
      },
    };
    return axiosInstance.put(url.data.url, file, options);
  };

  static approve = async (id) => {
    const axiosInstance = this.createAxios();
    const endpoint = endpoints.api.document.approve(id);
    const response = await axiosInstance.get(endpoint);
    return response.data;
  };

  static decline = async (id, reason) => {
    const axiosInstance = this.createAxios();
    const endpoint = endpoints.api.document.decline(id);
    const response = await axiosInstance.put(endpoint, reason);
    return response.data;
  };

  static async upload(id, data) {
    const axiosInstance = this.createAxios();
    const endpoint = endpoints.api.document.upload(id);
    const response = await axiosInstance.put(endpoint, data);

    return response.data;
  }

  static async delete(id) {
    const axiosInstance = this.createAxios();

    const endpoint = endpoints.api.document.delete(id);
    const response = await axiosInstance.delete(endpoint, id);

    return response.data;
  }

  static async documents(type, uuid) {
    const axiosInstance = this.createAxios();
    const endpoint = endpoints.api.document.list(type, uuid);
    console.log('Endopint:', endpoint, uuid);
    const response = await axiosInstance.get(endpoint);
    return response.data;
  }
}

export default DocumentService;
