import PropTypes from 'prop-types';
// @mui
import MenuItem from '@mui/material/MenuItem';
import { RHFSelect } from 'src/components/hook-form';

export default function SelectForm({ item, onChange, onNext }) {
  return (
    <RHFSelect
      name={item.id}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          onNext();
        }
      }}
    >
      {item.options.map((option) => (
        <MenuItem
          key={option.value}
          value={option.value}
          onClick={() => onChange(option.value, item.id)}
          sx={{whiteSpace:"wrap"}}
        >
          {option.label}
        </MenuItem>
      ))}
    </RHFSelect>
  );
}

SelectForm.propTypes = {
  item: PropTypes.object,
  onChange: PropTypes.func,
  onNext: PropTypes.func,
};
