import PropTypes from 'prop-types';
// @mui
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import { RHFTextField } from 'src/components/hook-form';
import NumericFormatCustom from 'src/utils/money-input-format';

export default function InputForm({ item, onChange, onNext }) {
  const renderStartAdornment = () => {
    if (item.variation === 'number' && item.format === 'fCurrency') {
      return (
        <InputAdornment position="start">
          <Box component="span" sx={{ color: 'text.disabled' }}>
            €
          </Box>
        </InputAdornment>
      );
    }
    return null;
  };

  return (
    <RHFTextField
      fullWidth
      type={item.variation}
      name={item.id}
      variant="outlined"
      placeholder={item.placeholder}
      handleOnChange={onChange}
      onKeyPress={(event) => {
        if (event.key === 'Enter') {
          onNext();
        }
      }}
      InputProps={{
        startAdornment: renderStartAdornment(),
        inputComponent: item.type === "money"? NumericFormatCustom :null
      }}
    />
  );
}

InputForm.propTypes = {
  item: PropTypes.object,
  onChange: PropTypes.func,
  onNext: PropTypes.func,
};
