import axios, { endpoints } from 'src/utils/axios';

const interceptorCorrect = (response) => response;

const interceptorError = (error) => Promise.reject(error);

class Auth {
  static axiosInstance;

  static createAxios = () => {
    Auth.axiosInstance = axios.create({
      withCredentials: true,
      defaults: {
        headers: axios.defaults.headers,
      },
    });

    Auth.axiosInstance.interceptors.response.use(interceptorCorrect, interceptorError);

    return Auth.axiosInstance;
  };

  static login = async () => {
    const axiosInstance = this.createAxios();
    const endpoint = endpoints.api.auth.login;
    const response = await axiosInstance.get(endpoint);
    return response.data;
  };

  static detailsUserGoogle = async (accessToken) => {
    const axiosInstance = this.createAxios();
    const endpoint = 'https://www.googleapis.com/oauth2/v3/userinfo';
    const response = await axiosInstance.get(endpoint);
    return response;
  };

  static getUser = async (user) => {
    const axiosInstance = this.createAxios();
    const endpoint = endpoints.api.auth.getUser;
    const response = await axiosInstance.post(endpoint, user);
    return response;
  };

  static registration = async (user) => {
    const axiosInstance = this.createAxios();
    const endpoint = endpoints.api.auth.registration;
    const response = await axiosInstance.post(endpoint, user);
    return response.data;
  };

  static sendVerificationMail = async (mail) => {
    const axiosInstance = this.createAxios();
    const endpoint = endpoints.api.auth.verificationMail;
    const response = await axiosInstance.post(endpoint, mail);
    return response;
  };

  static deleteUser = async (uid) => {
    const axiosInstance = this.createAxios();
    const endpoint = endpoints.api.auth.deleteUser;
    const response = await axiosInstance.post(endpoint, uid);
    return response;
  };

  static resetPassword = async (email) => {
    const axiosInstance = this.createAxios();
    const endpoint = endpoints.api.auth.resetPassword;
    const response = await axiosInstance.post(endpoint, email);
    return response;
  };

  static test = async () => {
    const axiosInstance = this.createAxios();
    const endpoint = endpoints.api.auth.test;
    const response = await axiosInstance.post(endpoint);
    return response;
  };
}

export default Auth;
