import { useCallback, useEffect, useState } from 'react';

import { getAuth, applyActionCode, verifyPasswordResetCode } from 'firebase/auth';

import PropTypes from 'prop-types'; // @mui
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import Button from '@mui/material/Button';
import { useRouter } from 'src/routes/hooks';
import { firebaseApp } from 'src/config-global';

import LoginRedirect from 'src/utils/redirect-login';
import { paths } from 'src/routes/paths';

// ----------------------------------------------------------------------

export default function VerifyMailView({ sx, ...other }) {
  const router = useRouter();

  const [openDialog, setopenDialog] = useState(false);

  const [redirectMessage, setMessage] = useState();

  const openPopup = useCallback(
    () =>
      new Promise((resolve) => {
        setopenDialog(true);
        const handleClose = () => {
          setopenDialog(false);
          resolve();
        };
      }),
    []
  );

  const handleVerifyEmail = useCallback(
    (firebaseAuth, actionCode) => {
      applyActionCode(firebaseAuth, actionCode)
        .then((resp) => {
          LoginRedirect({
            message: 'Se verifico su cuenta con exito',
            severity: 'success',
            redirect: router,
          });
        })
        .catch(async (error) => {
          // Code is invalid or expired. Ask the user to verify their email address again.
          await openPopup();
        });
    },
    [router, openPopup]
  );

  const handleResetPassword = useCallback(
    (auth, actionCode) => {
      verifyPasswordResetCode(auth, actionCode)
        .then((email) => {
          // const accountEmail = email;

          sessionStorage.setItem('code', actionCode);

          router.push(paths.auth.newPassword);
        })
        .catch(async (error) => {
          // Invalid or expired action code. Ask user to try to reset the password
          // again.
          await openPopup();
        });
    },
    [openPopup, router]
  );

  useEffect(() => {
    const firebaseAuth = getAuth(firebaseApp, {
      /* extra options */
    });

    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });

    const { mode } = params;
    // Get the one-time code from the query parameter.
    const actionCode = params.oobCode;
    // (Optional) Get the continue URL from the query parameter if available.
    // const continueUrl = params.continueUrl
    // (Optional) Get the language code if available.
    // const lang = "es"

    // check has the parameters we need
    if (!mode || !actionCode) {
      LoginRedirect({ message: 'El enlace no es valido', severity: 'error', redirect: router });
      return;
    }

    switch (mode) {
      case 'resetPassword':
        // Display reset password handler and UI.
        setMessage('Pulse en "¿Has olvidado tu contraseña?" para que podamos enviarle otro enlace');
        handleResetPassword(firebaseAuth, actionCode);
        break;
      case 'recoverEmail':
        // Display email recovery handler and UI.
        break;
      case 'verifyEmail':
        // Display email verification handler and UI.
        setMessage('Inicie sesion para que podamos enviarle otro enlace');
        handleVerifyEmail(firebaseAuth, actionCode);
        break;
      default:
      // Error: invalid mode.
    }
  }, [router, handleVerifyEmail, handleResetPassword]);

  const handleClose = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      setopenDialog(false);
    }
  };

  return (
    <>
      <Box
        sx={{
          px: 5,
          width: 1,
          flexGrow: 1,
          minHeight: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          ...sx,
        }}
        {...other}
      >
        <LinearProgress color="inherit" sx={{ width: 1, maxWidth: 360 }} />
      </Box>
      <Dialog
        open={openDialog}
        onClose={(event) => {
          handleClose(event);
        }}
        style={{ justifyContent: 'center' }}
      >
        <DialogTitle>El enlace ha expirado</DialogTitle>
        <DialogContent>
          <DialogContentText align="center">
            El enlace que te enviamos expiro, debemos enviarte uno nuevo
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center' }}>
          <Button
            variant="contained"
            color='primary'
            onClick={() => {
              LoginRedirect({ message: redirectMessage, severity: 'info', redirect: router });
            }}
          >
            Recibir otro enlace
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

VerifyMailView.propTypes = {
  sx: PropTypes.object,
};
