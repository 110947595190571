import React, { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// components
import { LoadingScreen } from 'src/components/loading-screen';
import AuthModernCompactStepLayout from 'src/layouts/auth/modern-compact-step';
import ClientLandingLayout from 'src/layouts/clients_landing/layout';
import DashboardLayout from 'src/layouts/clients/layout';
import WelcomeView from 'src/pages/clients/welcome';
import KYCView from 'src/sections/clients/kyc/kyc-view';
import FilesPage from 'src/pages/clients/files';
import UploadDocuments from 'src/pages/clients/load-documents';
import FinalPayment from 'src/pages/clients/final-payment';
import Result from 'src/pages/clients/result';
import { OverviewAppView } from 'src/sections/clients/clients_landing/landing-view';
import { UnnaxView } from 'src/sections/clients/unnax';

const PageFiles = lazy(() => import('src/pages/clients/file-landing'));

// ----------------------------------------------------------------------

export const clientsRoutes = [
  {
    path: 'clients',
    element: (
      <Suspense fallback={<LoadingScreen />}>
        <Outlet />
      </Suspense>
    ),
    children: [
      {
        path: 'welcome/:uuid',
        element: (
          <AuthModernCompactStepLayout customMaxWidth={800}>
            <WelcomeView />
          </AuthModernCompactStepLayout>
        ),
      },
      {
        path: 'landing/:uuid',
        element: (
          <ClientLandingLayout>
            <OverviewAppView />
          </ClientLandingLayout>
        ),
      },
      {
        path: 'bank/:uuid',
        element: (
          <AuthModernCompactStepLayout>
            <UnnaxView />
          </AuthModernCompactStepLayout>
        ),
      },
      {
        path: 'questionnaire/:uuid',
        element: (
          <AuthModernCompactStepLayout>
            <KYCView />
          </AuthModernCompactStepLayout>
        ),
      },
      {
        path: 'files/:uuid',
        element: (
          <AuthModernCompactStepLayout stepper={false}>
            <FilesPage />
          </AuthModernCompactStepLayout>
        ),
      },
      {
        path: 'files/upload/:tipoDocumento/:uuid',
        element: (
          <AuthModernCompactStepLayout>
            <UploadDocuments />
          </AuthModernCompactStepLayout>
        ),
      },
      {
        path: 'result/:uuid',
        element: (
          <AuthModernCompactStepLayout customMaxWidth={670}>
            <Result />
          </AuthModernCompactStepLayout>
        ),
      },
      {
        path: 'payment/:uuid',
        element: (
          <AuthModernCompactStepLayout customMaxWidth={800}>
            <FinalPayment />
          </AuthModernCompactStepLayout>
        ),
      },
      {
        path: 'filesLanding/:uuid',
        element: (
          <DashboardLayout>
            <PageFiles />
          </DashboardLayout>
        ),
      },
    ],
  },
];
