// ----------------------------------------------------------------------

export const HEADER = {
  H_MOBILE: 70,
  H_DESKTOP: 70,
  H_DESKTOP_OFFSET: 100 - 20,
};

export const NAV = {
  W_VERTICAL: 240,
  W_MINI: 88,
};
