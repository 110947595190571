import PropTypes from 'prop-types';
// @mui
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/paths';
import { Link } from 'react-router-dom';
// utils
import { getUuid } from 'src/utils/get-uuid';

// ----------------------------------------------------------------------

export default function ButtonsKYC({
  activeStep,
  steps,
  solution,
  handleBack,
  emptyValue,
  handleNext,
  isLoading,
}) {
  const uuid = getUuid();

  return (
    <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }} justifyContent="space-between">
      <Stack sx={{ textAlign: 'left' }} direction={{ xs: 'column', sm: 'row' }} spacing={1}>
        {!isLoading ? (
          <Button component={Link} to={`/clients/welcome/${uuid}`} variant="soft">
            Volver atrás
          </Button>
        ) : (
          <Button variant="soft" disabled>
            Volver atrás
          </Button>
        )}
      </Stack>
      <Stack sx={{ textAlign: 'right' }} direction={{ xs: 'column', sm: 'row' }} spacing={1}>
        {!solution && (
          <Button color="inherit" disabled={activeStep === 0} onClick={handleBack}>
            Atrás
          </Button>
        )}
        {!solution && emptyValue.length === 0 && activeStep < steps.length && (
          <Button color="primary" variant="contained" onClick={handleNext}>
            {activeStep === steps.length - 1 ? 'Finalizar' : 'Siguiente'}
          </Button>
        )}
      </Stack>
    </Stack>
  );
}

ButtonsKYC.propTypes = {
  activeStep: PropTypes.number,
  steps: PropTypes.array,
  solution: PropTypes.object,
  handleNext: PropTypes.func,
  emptyValue: PropTypes.array,
  handleBack: PropTypes.func,
  isLoading: PropTypes.bool,
};
