import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import AdminDashboardLayout from 'src/layouts/agents';
import FullScreenLayout from 'src/layouts/full_screen/layout';
// components
import { LoadingScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------
const ClientsPage = lazy(() => import('src/pages/admins/app'));
const AccountClient = lazy(() => import('src/pages/admins/account'));
const ReportingApp = lazy(() => import('src/pages/admins/reporting'));

const analyst = {
  path: '',
  element: (
    <AuthGuard>
      <Suspense fallback={<LoadingScreen />}>
        <Outlet />
      </Suspense>
    </AuthGuard>
  ),
  children: [
    {
      element: (
        <AdminDashboardLayout>
          <ClientsPage />
        </AdminDashboardLayout>
      ),

      index: true,
    },
    {
      path: 'reporting',
      element: (
        <AdminDashboardLayout>
          <ReportingApp />
        </AdminDashboardLayout>
      ),
    },
    {
      path: 'client',
      children: [
        {
          path: ':id',
          element: (
            <AdminDashboardLayout>
              <AccountClient />
            </AdminDashboardLayout>
          ),
        },
      ],
    },
  ],
};

export const analystsRoutes = [
  {
    path: 'analysts',
    children: [analyst],
  },
];
