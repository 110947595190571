import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
// Mui
import { styled } from '@mui/material/styles';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { DialogContent, Slide } from '@mui/material';
import { Stack } from '@mui/system';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
// form validation
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
// service
import ClientService from '../../../services/client';
// utils
import { errorAlert } from '../../../utils/error-dialog';
import { getUuid } from '../../../utils/get-uuid';

const Img = styled('img')({
  display: 'block',
  maxWidth: '100%',
  maxHeight: '100%',
});

const acceptText = () => (
  <Typography variant="caption">
    Acepto y confirmo que he leído los <u>&quot;Términos y condiciones&quot;</u> de Unnax Regulatory
    Services, EDE, Sl, que los entiendo y los acepto.
  </Typography>
);

const confirmText = () => (
  <Typography variant="caption">
    Confirmo que he leído la <u>&quot;Política de Privacidad&quot;</u> de Unnax Regolatory Services,
    EDE, SL, que los entiendo y los acepto, incluyendo la cesión de datos agregados a Unnax Payments
    Systems SLU, para que evalúe mi perfil económico accediendo una única vez a las cuentas
    bancarias que he seleccionado.
  </Typography>
);

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="left" ref={ref} {...props} />
));

const validationSchema = Yup.object().shape({
  user: Yup.string().required('El usuario es obligatorio'),
  password: Yup.string().required('La contraseña es obligatoria'),
  acceptTerms: Yup.boolean().oneOf([true], 'Debes aceptar los términos y condiciones'),
  confirmPrivacy: Yup.boolean().oneOf([true], 'Debes aceptar la política de privacidad'),
});

export default function DialogBankAccount(props) {
  const { onClose, open, bank, setOpenBank } = props;
  const uuid = getUuid();
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      user: '',
      password: '',
      acceptTerms: false,
      confirmPrivacy: false,
    },
  });

  const handleClose = () => {
    onClose();
  };

  const handleBack = () => {
    setOpenBank(true);
    onClose();
  };

  const handleConnectBankAccount = (data) => {
    console.log('Se rellena y entrega:', data);
    ClientService.process({ uuid, process: 'bank' })
      .then((res) => {
        navigate(`/clients/landing/${uuid}`);
      })
      .catch((err) => {
        errorAlert(
          'Ocurrio un error inesperado',
          'Ha ocurrido un error inesperado actualizar el proceso de cliente'
        );
        console.error('Error updating client data:', err);
      });
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      scroll="paper"
      TransitionComponent={Transition}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle>
        <Stack direction="column" alignItems="center" spacing={2} sx={{ mt: 3 }}>
          <Img src="https://www.unnax.mx/wp-content/uploads/2021/06/Background.png" width={150} />
          <Typography variant="h5">Introduce tus credenciales</Typography>
        </Stack>
      </DialogTitle>
      <DialogContent dividers>
        {bank && (
          <form onSubmit={handleSubmit(handleConnectBankAccount)}>
            <Stack direction="column" alignItems="center" spacing={2}>
              <Img src={bank.img} width={150} />

              <Controller
                name="user"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Usuario"
                    variant="filled"
                    fullWidth
                    error={!!errors.user}
                    helperText={errors.user?.message}
                  />
                )}
              />

              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Contraseña"
                    variant="filled"
                    type="password"
                    fullWidth
                    error={!!errors.password}
                    helperText={errors.password?.message}
                  />
                )}
              />

              <Stack direction="row" alignItems="flex-start">
                <Controller
                  name="acceptTerms"
                  control={control}
                  render={({ field }) => <Checkbox {...field} checked={field.value} />}
                />
                {acceptText()}
              </Stack>
              {errors.acceptTerms && (
                <Typography variant="caption" color="error">
                  {errors.acceptTerms.message}
                </Typography>
              )}

              <Stack direction="row" alignItems="flex-start">
                <Controller
                  name="confirmPrivacy"
                  control={control}
                  render={({ field }) => <Checkbox {...field} checked={field.value} />}
                />
                {confirmText()}
              </Stack>
              {errors.confirmPrivacy && (
                <Typography variant="caption" color="error">
                  {errors.confirmPrivacy.message}
                </Typography>
              )}

              <Stack direction="row" justifyContent="space-evenly" sx={{ minWidth: '100%' }}>
                <Button size="large" variant="contained" onClick={handleBack}>
                  Volver
                </Button>
                <Button size="large" variant="contained" type="submit">
                  Confirmar
                </Button>
              </Stack>
            </Stack>
          </form>
        )}
      </DialogContent>
    </Dialog>
  );
}

DialogBankAccount.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  bank: PropTypes.object.isRequired,
  setOpenBank: PropTypes.func.isRequired,
};
