import PropTypes from 'prop-types';
import { useEffect, useState, useCallback } from 'react';
import ChatService from 'src/services/chat';
import { useRouter } from 'src/routes/hooks';
import { useAuthContext } from 'src/auth/hooks';
import { useSocket } from 'src/auth/context/socket-context';
import { useTheme } from '@emotion/react';

// @mui
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import { Alert } from '@mui/material';
import EmptyContent from 'src/components/empty-content/empty-content';
import ChatHeaderDetail from '../chat-header-detail';
import ChatInputList from '../chat-input-list';

// ----------------------------------------------------------------------

export default function ChatView({
  email,
  open = null,
  setChatOpen = null,
  user_id = null,
  showBadge = null,
}) {
  const [chatData, setChatData] = useState();
  const { socket, recieveMessageNotifications } = useSocket();
  const [alertMsg, setAlertMsg] = useState();

  const themee = useTheme();

  const getChats = useCallback(async (userMail) => {
    try {
      // use the email recieved and get the full conversation
      const data = await ChatService.getChat({ email: userMail });
      setChatData(data);
    } catch (error) {
      setAlertMsg('Ocurrio un error al cargar los mensajes');
    }
  }, []);
  useEffect(() => {
    // eslint-disable-next-line react/destructuring-assignment

    if (email) {
      getChats(email);
    }

    // turns of the message notifications from this user and turns them back on when closing the chat
    if (socket) {
      socket.off('message_notification');
      recieveMessageNotifications(open, setChatOpen, user_id, showBadge);
    }
    return () => {
      if (socket) {
        socket.off('message_notification');
        recieveMessageNotifications(open, setChatOpen, user_id, showBadge);
      }
    };
  }, [email, getChats, recieveMessageNotifications, open, setChatOpen, socket, user_id, showBadge]);

  const dummy_data = {
    chat_id: 'chat_uuid_12345',
    reciever_data: {
      name: 'Soporte Sophia',
      email: 'soporte@gmail.com',
      phone_number: '+34 634 59 08 03',
    },
    messages: [
      {
        uuid: 'msg_uuid_1',
        message: 'Hola, ¿cómo puedo ayudarte hoy?',
        sent_by: 'A',
        sent: '2024-07-01T09:00:00Z',
      },
      // {
      //   uuid: 'msg_uuid_2',
      //   message: 'Hola, tengo una pregunta sobre mi solicitud de financiamiento.',
      //   sent_by: 'C',
      //   sent: '2024-07-01T09:01:00Z',
      // },
      // {
      //   uuid: 'msg_uuid_3',
      //   message: 'Claro, ¿en qué puedo ayudarte?',
      //   sent_by: 'A',
      //   sent: '2024-07-01T09:02:00Z',
      // },
      // {
      //   uuid: 'msg_uuid_4',
      //   message: 'Quisiera saber por qué mi solicitud fue denegada.',
      //   sent_by: 'C',
      //   sent: '2024-07-01T09:03:00Z',
      // },
      // {
      //   uuid: 'msg_uuid_5',
      //   message:
      //     'Según nuestro análisis, tu solicitud fue denegada debido a una baja puntuación crediticia.',
      //   sent_by: 'A',
      //   sent: '2024-07-01T09:05:00Z',
      // },
      // {
      //   uuid: 'msg_uuid_6',
      //   message: 'Entiendo, gracias por la información.',
      //   sent_by: 'C',
      //   sent: '2024-07-01T09:06:00Z',
      // },
    ],
  };

  const renderHead = (
    <Stack
      direction="row"
      alignItems="center"
      flexShrink={0}
      sx={{ pr: 1, pl: 2.5, py: 1, minHeight: 72, backgroundColor: themee.palette.primary.main }}
    >
      <ChatHeaderDetail agent={dummy_data.reciever_data.name} setOpen={setChatOpen} />
    </Stack>
  );

  const renderMessages = dummy_data && (
    <Stack
      sx={{
        width: 1,
        height: 1,
        overflow: 'hidden',
      }}
    >
      <ChatInputList
        messageList={dummy_data.messages}
        reciever={dummy_data.reciever_data}
        chatID={dummy_data.chat_id}
        user_id={user_id}
      />
    </Stack>
  );
  return (
    <>
      {/* {!!alertMsg && <Alert severity="error">{alertMsg}</Alert>} */}

      {/* eslint-disable-next-line no-nested-ternary */}
      {dummy_data ? (
        <Stack component={Card} direction="row" sx={{ height: '72vh' }}>
          <Stack
            sx={{
              width: 1,
              height: 1,
              overflow: 'hidden',
            }}
          >
            {renderHead}

            <Stack
              direction="row"
              sx={{
                width: 1,
                height: 1,
                overflow: 'hidden',
                borderTop: (theme) => `solid 1px ${theme.palette.divider}`,
              }}
            >
              {renderMessages}

              {/* {details && <ChatRoom conversation={conversation} participants={participants} />} */}
            </Stack>
          </Stack>
        </Stack>
      ) : // eslint-disable-next-line react/destructuring-assignment
      !email.email ? (
        <Stack component={Card} direction="row" sx={{ height: '50vh' }}>
          <EmptyContent title="El cliente no se ha registrado" />
        </Stack>
      ) : (
        ''
      )}
    </>
  );
}

ChatView.propTypes = {
  email: PropTypes.string,
  open: PropTypes.bool,
  setChatOpen: PropTypes.func,
  user_id: PropTypes.string,
  showBadge: PropTypes.func,
};
