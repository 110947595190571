import React from 'react';
// mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

export default function PowerBy() {
  const stackStyle = {
    justifyContent: 'center',
    pt: 2,
  };

  return (
    <Stack direction="row" alignItems="center" sx={stackStyle}>
      <Typography variant="body2">Powered by </Typography>
      <Link
        key="simplAI"
        href="https://simplai.es/"
        variant="body2"
        target="_blank"
        rel="noopener"
        sx={{ display: 'table' }}
      >
        <Box component="img" src="/logo/SimplAI.png" sx={{ width: 70, cursor: 'pointer' }} />
      </Link>
    </Stack>
  );
}
