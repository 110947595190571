import React, { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import FullScreenLayout from 'src/layouts/full_screen/layout';

// components
import { LoadingScreen } from 'src/components/loading-screen';
import WhatsAppContainer from 'src/components/chatbot/whatsapp';
import AuthModernCompactLayout from 'src/layouts/auth/modern-compact';
import VerifyMailView from 'src/sections/clients/verify-mail-view';

// ----------------------------------------------------------------------

const PagePayment = lazy(() => import('src/pages/clients/payment'));
const PageOnboarding = lazy(() => import('src/pages/clients/onboarding'));
const PageValidation = lazy(() => import('src/pages/clients/validation'));
const VerifyModernPage = lazy(() => import('src/pages/clients/verify'));

// ----------------------------------------------------------------------

export const fullScreenRoutes = [
  {
    path: 'clients',
    element: (
      <Suspense fallback={<LoadingScreen />}>
        <Outlet />
        {/* <WhatsAppContainer /> */}
      </Suspense>
    ),
    children: [
      {
        path: 'payment',
        element: (
          <AuthGuard>
            <FullScreenLayout>
              <PagePayment />
            </FullScreenLayout>
          </AuthGuard>
        ),
      },
      {
        path: 'onboarding',
        element: (
          <AuthGuard>
            <FullScreenLayout>
              <PageOnboarding />
            </FullScreenLayout>
          </AuthGuard>
        ),
      },
      {
        path: 'validation',
        element: (
          <AuthModernCompactLayout customMaxWidth={1000}>
            <PageValidation />
          </AuthModernCompactLayout>
        ),
      },
      {
        path: 'verify',
        element: (
          <AuthModernCompactLayout>
            <VerifyModernPage />
          </AuthModernCompactLayout>
        ),
      },
      {
        path: 'verify-email',
        element: <VerifyMailView />,
      },
    ],
  },
];
