import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
// @mui
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// components
import { Upload, UploadHandler } from 'src/components/upload';

// ----------------------------------------------------------------------

export default function AppUploadFiles({
  open,
  onClose,
  loading,
  setLoading,
  file,
  setStatus,
  onUpload,
}) {
  const theme = useTheme();
  const preview = useBoolean();
  const { id, name } = file;

  const [uploadErrorMsg, setUploadErrorMsg] = useState();

  const { files, handleDropMultiFile, handleRemoveFile, handleRemoveAllFiles, handleUpload } =
    UploadHandler(file, setUploadErrorMsg);

  const handleUploads = useCallback(async () => {
    try {
      setLoading(true);
      await handleUpload();
      setStatus('finished');
      onClose();
    } catch (error) {
      console.error('Error handleUploads', error);
      setLoading(false);
    }
  }, [handleUpload, setLoading, onClose, setStatus]);

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}
      transitionDuration={{
        enter: theme.transitions.duration.shortest,
        exit: theme.transitions.duration.shortest - 80,
      }}
    >
      <DialogContent>
        <Upload
          // multiple
          // title="Carga de documento"
          // open={open}
          // onClose={onClose}
          // thumbnail={preview.value}
          // files={files}
          // onDrop={handleDropMultiFile}
          // onRemove={handleRemoveFile}
          // onRemoveAll={handleRemoveAllFiles}
          // onUpload={handleUploads}

          loading={loading}
          open={open}
          onClose={onClose}
          title="Carga de documentación"
          multiple
          thumbnail={false}
          files={files}
          onDrop={handleDropMultiFile}
          onRemove={handleRemoveFile}
          onRemoveAll={handleRemoveAllFiles}
          onUpload={handleUploads}
          errorMsg={uploadErrorMsg}
        />
      </DialogContent>

      <DialogActions>
        <Button color="inherit" variant="outlined" onClick={onClose}>
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

AppUploadFiles.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  loading: PropTypes.bool,
  setLoading: PropTypes.func,
  file: PropTypes.object,
  onUpload: PropTypes.func,
  setStatus: PropTypes.func,
};
