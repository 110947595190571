/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';

import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { alpha, useTheme } from '@mui/material/styles';

import { bgGradient } from 'src/theme/css';
import { fCurrency } from 'src/utils/format-number';
// ----------------------------------------------------------------------

export default function ClientWelcome({ client, currentLabel, currentStep, img, ...other }) {
  const theme = useTheme();

  const proceso =
    // eslint-disable-next-line no-nested-ternary
    client.recomendation === 'DENEGAR' ? 'rechazada' : currentStep === 6 ? 'finalizada' : 'en proceso de evaluación';
  console.log('Current step en labl', currentLabel, currentStep);
  const importe = fCurrency(client.finance_amount);

  return (
    <Card>
      <Stack
        flexDirection={{ xs: 'column', md: 'row' }}
        sx={{
          ...bgGradient({
            direction: '135deg',
            startColor: alpha(theme.palette.primary.light, 0.2),
            endColor: alpha(theme.palette.primary.main, 0.2),
          }),
          height: { md: 1 },
          borderRadius: 2,
          position: 'relative',
          color: 'primary.darker',
          backgroundColor: 'common.white',
        }}
      >
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{
            p: {
              xs: theme.spacing(0, 3, 0, 3),
              md: theme.spacing(3),
            },
            textAlign: 'center',
          }}
        >

          <Typography
            variant="body2"
            sx={{
              opacity: 0.8,
              maxWidth: 360,
              // mb: { xs: 3, xl: 5 },
              fontWeight: 'bold',
              fontSize: '1.3rem',
            }}
          >
            Has solicitado correctamente un préstamo por un importe de {importe} en un plazo de {client.term} {' '}
            meses. ¡Ya estás más cerca de poder disponer de él!
          </Typography>
        </Stack>

        {img && (
          <Stack
            component="span"
            justifyContent="center"
            sx={{
              height: 230,
              mx: 'auto',
            }}
          >
            <img
              src={
                currentStep === 6
                  ? '/assets/illustrations/characters/character_9.png'
                  : client.recomendation === 'DENEGAR'
                    ? '/assets/illustrations/characters/character_6.png'
                    : '/assets/illustrations/characters/character_3.png'
              }
              alt="Descripción de la 3"
              style={{
                maxWidth: '100%',
                height: '80%',
              }}
            />
          </Stack>
        )}
      </Stack>
    </Card>
  );
}

ClientWelcome.propTypes = {
  client: PropTypes.object.isRequired,
  currentLabel: PropTypes.string.isRequired,
  currentStep: PropTypes.number.isRequired,
  img: PropTypes.node,
};
