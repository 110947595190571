import * as React from 'react';
import PropTypes from 'prop-types';
import { NumericFormat } from 'react-number-format';



const NumericFormatCustom =React.forwardRef((props, ref) => {
      const { onChange, ...other } = props;
  
      return (
        <NumericFormat
          {...other}
          getInputRef={ref}
          onValueChange={(values) => {
            onChange({
              target: {
                name: props.name,
                value: values.value,
              },
            });
          }}
          thousandSeparator="." 
          decimalSeparator=","
          valueIsNumericString
          prefix="€"
        />
      );
    },
  );
  
  NumericFormatCustom.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };

export default NumericFormatCustom