// @mui
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import KYCComponent from './kyc';

// ----------------------------------------------------------------------
export default function KYCView() {
  const showKYC = (
    <>
      <Typography variant="h3" textAlign="center">
        Cuestionario
      </Typography>
      <Typography variant="body2" textAlign="center" sx={{ color: 'text.secondary', m: 2 }}>
        A través de este cuestionario queremos conocer tu capacidad de pago para saber si las
        características de la financiación elegida se adapta a ti
        <br />
        <br />
        Por favor contesta cada una de las siguientes preguntas:
      </Typography>
      <KYCComponent />
    </>
  );

  return <Stack spacing={1}>{showKYC}</Stack>;
}
