import React from 'react';
import PropTypes from 'prop-types';
// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
// components
import Main from './main';
import PowerBy from '../../components/power-by/power-by';
import { HeaderSimple as Header } from '../_common';

// ----------------------------------------------------------------------

export default function FullScreenLayout({ children }) {
  return (
    <>
      <Box
        sx={{
          minHeight: 1,
          display: 'flex',
          flexDirection: { xs: 'column', lg: 'row' },
        }}
      >
        <Main>{children}</Main>
      </Box>
      <PowerBy />
    </>
  );
}

FullScreenLayout.propTypes = {
  children: PropTypes.node,
};
