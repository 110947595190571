import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useSocket } from 'src/auth/context/socket-context';
import { useAuthContext } from 'src/auth/hooks';

import ChatMessageInput from './chat-message-input';
import ChatMessageList from './chat-message-list';
// ----------------------------------------------------------------------

// this is an intermediate component created to handle new messages, it keeps the previous one
// from re-rendering every time there is a new message and calling the db over and over
export default function ChatInputList({ messageList = [], reciever, chatID, user_id = null }) {
  const { socket, recieveMessages } = useSocket();

  const [messages, setMessages] = useState();

  const { user } = useAuthContext();

  useEffect(() => {
    setMessages(messageList);
    // turns on reciving messages and turns it off when closing the chat
    recieveMessages(setMessages, user_id);

    return () => {
      if (socket) {
        socket.off('recive_message');
      }
    };
  }, [setMessages, recieveMessages, messageList, socket, user_id]);

  return (
    <>
      {messages && (
        <ChatMessageList messages={messages} reciever={reciever} chatID={chatID} user={user} />
      )}
      {messages && (
        <ChatMessageInput
          //
          chatID={chatID}
          disabled={!chatID}
          setMessages={setMessages}
          user={user}
        />
      )}
    </>
  );
}

ChatInputList.propTypes = {
  messageList: PropTypes.array,
  reciever: PropTypes.object,
  chatID: PropTypes.string,
  user_id: PropTypes.string,
};
