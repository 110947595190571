import React, { useEffect } from 'react';
// @mui
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { updateCurrentUser } from 'firebase/auth';

// ----------------------------------------------------------------------

export default function PaymentBillingAddress({ user, data, setData }) {
  const handleChange = (e) => {
    const fieldName = e.target.name;
    const fieldValue = e.target.value;

    setData({
      ...data,
      [fieldName]: fieldValue,
    });
  };
  useEffect(() => {
    setData({
      name: user.name,
      phone: user.phone_number,
      email: user.email,
    });
  }, [setData, user]);

  return (
    <div style={{ width: '50%' }}>
      <Typography variant="h6">Datos de facturación</Typography>

      <Stack spacing={3} mt={2}>
        <TextField
          fullWidth
          name="name"
          label="Nombre"
          value={data.name ? data.name : ''}
          onChange={(e) => {
            handleChange(e);
          }}
        />
        <TextField
          fullWidth
          name="phone"
          label="Teléfono"
          value={data.phone ? data.phone : ''}
          onChange={(e) => {
            handleChange(e);
          }}
        />
        <TextField
          fullWidth
          name="email"
          label="Email"
          value={data.email ? data.email : ''}
          onChange={(e) => {
            handleChange(e);
          }}
        />
      </Stack>
    </div>
  );
}

PaymentBillingAddress.propTypes = {
  user: PropTypes.object,
  setData: PropTypes.func,
  data: PropTypes.object,
};
