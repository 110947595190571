/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
// mui
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Backdrop, CircularProgress } from '@mui/material';
//
import PropTypes from 'prop-types';
import Iconify from '../iconify';
import AppUploadFiles from '../../sections/clients/overview/app/app-upload-files';

export default function UploadButton({
  file,
  setUploaded,
  uploaded = false,
  align = 'flex-end',
  after = true,
}) {
  const theme = useTheme();

  const [openDialogFiles, setOpenDialogFiles] = useState(false);
  const [clientType, setClientType] = useState(file?.client_type[0]);
  const [fileStatus, setFileStatus] = useState('pending');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const handlers = {
    files: setOpenDialogFiles,
    backdrop: setLoading,
  };

  const handleOpenDialog = (dialogName) => {
    handlers[dialogName](true);
    setError(false);
  };

  const handleCloseDialog = (dialogName) => {
    handlers[dialogName](false);
    setLoading(false);
  };

  if (fileStatus === 'finished' && uploaded === false) {
    console.log('File status finished');
    setUploaded(true);
  }

  console.log('File:', file);

  return (
    <>
      <Button
        variant="contained"
        color={error ? 'error' : fileStatus === 'finished' || uploaded ? 'success' : 'blue'}
        sx={{
          borderRadius: '50%',
          width: file?.description === 'Último modelo presentado' ? '75px' : '50px',
          height: '50px',
          minWidth: 'auto',
          padding: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        onClick={fileStatus === 'finished' || uploaded ? null : () => handleOpenDialog('files')}
      >
        {fileStatus !== 'finished' && !uploaded && (
          <Iconify icon="iconamoon:cloud-upload" width={24} />
        )}
        {(fileStatus === 'finished' || uploaded) && (
          <Iconify icon="iconamoon:cloud-yes" width={24} />
        )}
      </Button>

      <AppUploadFiles
        open={openDialogFiles}
        file={file}
        loadTime={1000}
        onClose={() => handleCloseDialog('files')}
        setStatus={setFileStatus}
        loading={loading}
        setLoading={setLoading}
      />

      <Backdrop sx={{ color: '#fff', zIndex: () => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

UploadButton.propTypes = {
  file: PropTypes.object,
  setUploaded: PropTypes.func,
  uploaded: PropTypes.bool,
  after: PropTypes.bool,
  align: PropTypes.string,
};
