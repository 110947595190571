import PropTypes from 'prop-types';
import { RHFRadioGroup } from 'src/components/hook-form';

export default function RadioGroupForm({ item, onChange, onNext }) {
  return (
    <RHFRadioGroup
      row
      name={item.id}
      spacing={4}
      options={item.options}
      onChange={(event) => onChange(event.target.value, item.id)}
      onKeyPress={(event) => {
        if (event.key === 'Enter') {
          onNext();
        }
      }}
    />
  );
}

RadioGroupForm.propTypes = {
  item: PropTypes.object,
  onChange: PropTypes.func,
  onNext: PropTypes.func,
};
