import { memo } from 'react';
import PropTypes from 'prop-types';
import { useBank } from 'src/auth/context/bank-context';

// @mui
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
// theme
import { bgBlur } from 'src/theme/css';
// hooks
import { useAuthContext } from 'src/auth/hooks';
// components
import { NavSectionHorizontal } from 'src/components/nav-section';
import { ROLES } from 'src/_mock/_roles';
//
import Logo from 'src/components/logo';

import { HEADER } from '../config-layout';
import { useNavData } from './config-navigation';
import { HeaderShadow } from '../_common';

// ----------------------------------------------------------------------

function NavHorizontal({ unnax, unnaxAfter, files, refinance }) {
  const theme = useTheme();

  const { user } = useAuthContext();

  const {bankAggregation} = useBank()

  const navData = useNavData(unnax, unnaxAfter, files, refinance,bankAggregation,user?.type);

  return (
    <AppBar
      component="nav"
      sx={{
        top: HEADER.H_DESKTOP_OFFSET,
      }}
    >
      <Toolbar
        sx={{
          ...bgBlur({
            color: theme.palette.background.default,
          }),
        }}
      >
        <Logo sx={{ mx: 'auto', my: 2 }} />

        <NavSectionHorizontal
          data={navData}
          config={{
            currentRole: user?.role || ROLES.ADMIN,
          }}
        />
      </Toolbar>

      <HeaderShadow />
    </AppBar>
  );
}

NavHorizontal.propTypes = {
  unnax: PropTypes.bool,
  unnaxAfter: PropTypes.bool,
  files: PropTypes.bool,
  refinance: PropTypes.bool,
};

export default memo(NavHorizontal);
