import PropTypes from 'prop-types';
// @mui
import { RHFMultiSelect } from 'src/components/hook-form';

export default function MultiSelectForm({ item, onChange, onNext }) {
  return (
    <RHFMultiSelect 
      chip
      checkbox
      name={item.id}
      options={item.options}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          onNext();
        }
      }}
      handleOnChange={onChange}
    />
  );
}

MultiSelectForm.propTypes = {
  item: PropTypes.object,
  onChange: PropTypes.func,
  onNext: PropTypes.func,
};
