import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// @mui
import { styled, alpha } from '@mui/material/styles';
import Step from '@mui/material/Step';
import Stepper from '@mui/material/Stepper';
import StepLabel from '@mui/material/StepLabel';
import { bgGradient } from 'src/theme/css';
import Iconify from 'src/components/iconify/iconify';
import { useWidth } from 'src/hooks/use-responsive';

// ----------------------------------------------------------------------
const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  zIndex: 1,
  width: 30,
  height: 30,
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.text.disabled,
  backgroundColor:
    theme.palette.mode === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
  ...(ownerState.active && {
    // boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    color: theme.palette.common.white,
    ...bgGradient({
      startColor: theme.palette.secondary.light,
      endColor: theme.palette.secondary.main,
    }),
  }),
  // ...(ownerState.completed &&
  ...(!ownerState.emptyQuestion && {
    color: theme.palette.common.white,
    ...bgGradient({
      startColor: theme.palette.secondary.light,
      endColor: theme.palette.secondary.main,
    }),
  }),
  // ...(ownerState.completed &&
  ...(ownerState.emptyQuestion && {
    color: theme.palette.common.white,
    ...bgGradient({
      startColor: theme.palette.primary.light,
      endColor: theme.palette.primary.dark,
    }),
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className, icon, emptyQuestion } = props;

  const icons = {
    true: <Iconify icon="material-symbols:close" width={24} />,
    false: <Iconify icon="material-symbols:check" width={24} />,
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, emptyQuestion, active }} className={className}>
      {active ? String(icon) : icons[emptyQuestion]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  completed: PropTypes.bool,
  icon: PropTypes.number,
  emptyQuestion: PropTypes.bool,
};

export default function StepperKYC({ activeStep, steps, questions, emptyQuestions }) {
  const bkpoints = useWidth();

  return (
    <Stepper activeStep={activeStep} alternativeLabel>
      {steps.map((step, index) => {
        const stepProps = {};

        const questionsForStepIds = questions
          .filter((question) => question.step === step.id)
          .map((question) => question.id);
        const emptyQuestionsIds = emptyQuestions.map((question) => question.id);
        const emptyQuestion = questionsForStepIds.some((emptyQuestionId) =>
          emptyQuestionsIds.includes(emptyQuestionId)
        );

        return (
          <Step key={step.label} {...stepProps}>
            <StepLabel
              StepIconComponent={(props) => (
                <ColorlibStepIcon
                  {...props}
                  questions={questions}
                  step={step}
                  emptyQuestion={emptyQuestion}
                />
              )}
            >
              {bkpoints !== 'xs' ? step.label : ''}
            </StepLabel>
          </Step>
        );
      })}
    </Stepper>
  );
}

StepperKYC.propTypes = {
  activeStep: PropTypes.number,
  steps: PropTypes.array,
  questions: PropTypes.array,
  emptyQuestions: PropTypes.array,
};
