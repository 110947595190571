import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// @mui
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import ListItemText from '@mui/material/ListItemText';
import { Button } from '@mui/material';
import Iconify from 'src/components/iconify';
import { getUuid } from 'src/utils/get-uuid';
import ClientService from 'src/services/client';

// ----------------------------------------------------------------------
// header for the chat window, it takes an object with the user data
export default function ChatHeaderDetail({ agent, setOpen = null }) {
  const renderSingle = agent && (
    <Stack
      flexGrow={1}
      direction="row"
      alignItems="center"
      spacing={2}
      sx={{ width: '100%', color: 'white' }}
    >
      <Avatar
        src="https://api-dev-minimal-v510.vercel.app/assets/images/avatar/avatar_2.jpg"
        alt={agent}
      />
      <ListItemText primary={agent} />
      <Button
        type="button"
        onClick={() => {
          setOpen(false);
        }}
      >
        <Iconify icon="ph:x" width={30} color="white" style={{ border: '1px' }} />
      </Button>
    </Stack>
  );
  return (
    <>
      {renderSingle}

      <Stack flexGrow={1} />
      {/*
      <IconButton>
        <Iconify icon="solar:phone-bold" />
      </IconButton>
      <IconButton>
        <Iconify icon="solar:videocamera-record-bold" />
      </IconButton>
      <IconButton>
        <Iconify icon="eva:more-vertical-fill" />
      </IconButton>
      */}
    </>
  );
}

ChatHeaderDetail.propTypes = {
  agent: PropTypes.string,
  setOpen: PropTypes.func,
};
