import React from 'react';

import { PATH_LOGIN } from 'src/config-global';

// ----------------------------------------------------------------------

export default function LoginRedirect({ message, severity, redirect }) {
  sessionStorage.setItem('redirected', true);
  sessionStorage.setItem('redirectMessage', message);
  sessionStorage.setItem('redirectSeverity', severity);
  redirect.push(PATH_LOGIN);
}
