/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useParams, useNavigate } from 'react-router-dom';
// mui material components
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import CircularProgress from '@mui/material/CircularProgress';

// services
import ClientService from 'src/services/client';
import DocumentService from 'src/services/document';
// _mock
import { errorAlert } from 'src/utils/error-dialog';
// icons
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
// components
import { useSettingsContext } from '../../components/settings';
import UploadButton from '../../components/files/upload-button';

export default function UploadDocuments({ documentType, uuid, setStep, startType }) {
  const settings = useSettingsContext();
  const navigate = useNavigate();

  const [fileStatus, setFileStatus] = useState(settings.files ? 'finished' : 'pending');

  const [loaded, setLoaded] = useState(false);

  // error handling
  const [errorMsg, setErrorMsg] = useState('');
  const [errorTitle, setErrorTitle] = useState('');
  const [openDialog, setOpenDialog] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [files, setFiles] = useState([]);

  useEffect(() => {
    DocumentService.documents(documentType, uuid)
      .then((response) => {
        setFiles(response);
      })
      .catch((error) => {
        errorAlert(
          'Ocurrio un error inesperado',
          'Ha ocurrido un error al intentar aprobar el documento',
          setErrorMsg,
          setErrorTitle,
          setOpenDialog
        );
        console.error('Error fetching document DocumentService.documents:', error);
      });
  }, [setFiles, documentType, uuid]);

  const [loadedStates, setLoadedStates] = useState([]);

  useEffect(() => {
    setLoadedStates(Array(files.length).fill(false));
  }, [files]);

  const handleUploadedChange = (index, value) => {
    const newLoadedStates = [...loadedStates];
    newLoadedStates[index] = value;
    setLoadedStates(newLoadedStates);
  };

  // Checks if all files are loaded before finishing
  useEffect(() => {
    if (loadedStates.length > 0) {
      const allFilesLoaded = loadedStates.every(Boolean);
      if (allFilesLoaded) {
        setFileStatus('finished');
        settings.onFiles(true);
      }
    }
  }, [loadedStates, settings, navigate]);

  // Update process and go to next step if done
  useEffect(() => {
    if (fileStatus === 'finished') {
      ClientService.documentType({ uuid, documentType })
        .then((res) => {
          console.log('Response de actualizar process:', res);
        })
        .catch((err) => {
          errorAlert(
            'Ocurrio un error inesperado',
            'Ha ocurrido un error inesperado actualizar el proceso de cliente',
            setErrorMsg,
            setErrorTitle,
            setOpenDialog
          );
          console.error('Error updating client data:', err);
        });
      settings.onFiles(true);
      setIsLoading(true);
      console.log('Is loading seteado a true');
      setTimeout(() => {
        setIsLoading(false);
        navigate(`/clients/landing/${uuid}`);
      }, 4000);
    }
  }, [fileStatus, navigate, settings, uuid, documentType]);

  const renderHeader = (
    <>
      <Typography variant="h3">Documentos solicitados</Typography>
      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
        Por favor, adjunta cada uno de los siguientes documentos
      </Typography>
    </>
  );

  const renderCircularProgress = (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        mb: '1rem',
      }}
    >
      <CircularProgress size={30} />
      <Typography variant="h6" align="center" sx={{ ml: '10px', mt: '1rem' }}>
        Su solicitud está siendo gestionada
      </Typography>
    </Box>
  );

  const renderFiles = (
    <Stack direction={{ xs: 'column', md: 'column' }} spacing={3} sx={{ my: 1 }}>
      {files.map((file, index) => (
        <Grid item xs={12} sm={4} display="flex" alignItems="center" direction={{ md: 'row' }}>
          <UploadButton
            uploaded={loadedStates[index]}
            setUploaded={(value) => handleUploadedChange(index, value)}
            file={file}
          />
          <Typography sx={{ ml: '1rem' }}>{file.name}</Typography>
          <Tooltip title={file.description}>
            <Button size="small" variant="text">
              <HelpOutlineOutlinedIcon />
            </Button>
          </Tooltip>
        </Grid>
      ))}
    </Stack>
  );

  return (
    <Stack alignItems="center" spacing={2}>
      {renderHeader}
      {isLoading ? renderCircularProgress : renderFiles}
      <Stack
        sx={{ textAlign: 'left', alignSelf: 'flex-start', mt: '1em' }}
        direction={{ xs: 'column', sm: 'row' }}
        spacing={1}
      >
        {startType ? (
          <Button onClick={() => setStep(1)} variant="soft" disabled={isLoading}>
            Volver atrás
          </Button>
        ) : (
          <Button component={Link} to={`/clients/landing/${uuid}`} variant="soft">
            Volver atrás
          </Button>
        )}
      </Stack>
    </Stack>
  );
}

UploadDocuments.propTypes = {
  documentType: PropTypes.string,
  uuid: PropTypes.string,
  setStep: PropTypes.func,
  startType: PropTypes.bool,
};
