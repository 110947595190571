import PropTypes from 'prop-types';
import React, { useState, useCallback } from 'react';
// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import { Divider, IconButton, InputAdornment } from '@mui/material';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';

import CustomPopover, { usePopover } from '../custom-popover';

// hooks
import { useBoolean } from '../../hooks/use-boolean';
// components
import Iconify from '../iconify';
//
import PaymentNewCardDialog from './payment-new-card-dialog';

// ----------------------------------------------------------------------

const PAYMENT_OPTIONS = [
  {
    value: 'paypal',
    label: 'Paypal',
  },
  {
    value: 'bizum',
    label: 'Bizum',
  },
  {
    value: 'credit',
    label: 'Tarjeta crédito / débito',
  },
];

const CARD_OPTIONS = [
  {
    value: 'visa1',
    label: '**** **** **** 1212 - David Alcalde',
  },
  {
    value: 'mastercard',
    label: '**** **** **** 4545 - David Alcalde',
  },
];

// ----------------------------------------------------------------------

export default function PaymentMethods({
  cvv,
  expireDate,
  cardNumber,
  setCvv,
  setExpireDate,
  setCardNumber,
  payError,
}) {
  const newCard = useBoolean();
  const popover = usePopover();

  const [method, setMethod] = useState('paypal');

  const handleChangeMethod = useCallback((newValue) => {
    setMethod(newValue);
  }, []);
  return (
    <>
      <Box
        sx={{
          // p: 5,
          pl: 3,
          borderRadius: 2,
          width: '50%',
        }}
      >
        <Typography variant="h6" sx={{ mb: 2 }}>
          Datos de la tarjeta
        </Typography>

        <Stack spacing={2.5}>
          <TextField
            error={payError === 'cardNumber'}
            helperText={payError === 'cardNumber' ? 'Numero de tarjeta invalido' : null}
            autoFocus
            label="Número de tarjeta"
            placeholder="XXXX XXXX XXXX XXXX"
            InputLabelProps={{ shrink: true }}
            value={cardNumber || ''}
            onChange={(e) => {
              setCardNumber(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <CreditCardOutlinedIcon fontSize="small" />
                </InputAdornment>
              ),
            }}
          />

          <Stack spacing={2} direction="row">
            <TextField
              error={payError === 'expireDate'}
              helperText={payError === 'expireDate' ? 'Fecha de vencimiento invalida' : null}
              label="Fecha de vencimiento"
              placeholder="MM/YY"
              InputLabelProps={{ shrink: true }}
              value={expireDate || ''}
              onChange={(e) => {
                setExpireDate(e.target.value);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <CalendarMonthOutlinedIcon fontSize="small" />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              error={payError === 'cvv'}
              helperText={payError === 'cvv' ? 'Codigo invalido' : null}
              label="CVV/CVC"
              placeholder="***"
              InputLabelProps={{ shrink: true }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton size="small" edge="end" onClick={popover.onOpen}>
                      <Iconify icon="eva:info-outline" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              value={cvv || ''}
              onChange={(e) => {
                setCvv(e.target.value);
              }}
            />
          </Stack>
        </Stack>
      </Box>
      <PaymentNewCardDialog
        open={newCard.value}
        onClose={newCard.onFalse}
        setCvv={setCvv}
        setExpireDate={setExpireDate}
        setCardNumber={setCardNumber}
        cvv={cvv}
        expireDate={expireDate}
        cardNumber={cardNumber}
        payError={payError}
      />

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="bottom-center"
        sx={{ maxWidth: 200, typography: 'body2', textAlign: 'center' }}
      >
        Tres dígitos en la parte trasera de tu tarjeta
      </CustomPopover>
    </>
  );
}
PaymentMethods.propTypes = {
  cvv: PropTypes.string,
  expireDate: PropTypes.string,
  cardNumber: PropTypes.string,
  setCvv: PropTypes.func,
  setExpireDate: PropTypes.func,
  setCardNumber: PropTypes.func,
  payError: PropTypes.string,
};

// ----------------------------------------------------------------------

function OptionItem({ option, selected, isCredit, onOpen, ...other }) {
  const { value, label } = option;

  return (
    <Paper
      variant="outlined"
      key={value}
      sx={{
        p: 2.5,
        cursor: 'pointer',
        ...(selected && {
          boxShadow: (theme) => `0 0 0 2px ${theme.palette.text.primary}`,
        }),
      }}
      {...other}
    >
      <ListItemText
        primary={
          <Stack direction="row" alignItems="center">
            <Iconify
              icon={selected ? 'eva:checkmark-circle-2-fill' : 'eva:radio-button-off-fill'}
              width={24}
              sx={{
                mr: 2,
                color: selected ? 'primary.main' : 'text.secondary',
              }}
            />

            <Box component="span" sx={{ flexGrow: 1 }}>
              {label}
            </Box>

            <Stack spacing={1} direction="row" alignItems="center">
              {value === 'credit' && (
                <>
                  <Iconify icon="logos:mastercard" width={24} />,
                  <Iconify icon="logos:visa" width={24} />
                </>
              )}
              {value === 'paypal' && <Iconify icon="logos:paypal" width={24} />}
              {value === 'bizum' && (
                <Box component="img" src="/logo/bizum.png" sx={{ width: 28 }} />
              )}
              {value === 'cash' && <Iconify icon="solar:wad-of-money-bold" width={24} />}
            </Stack>
          </Stack>
        }
        primaryTypographyProps={{ typography: 'subtitle2' }}
      />

      {isCredit && (
        <Stack
          spacing={2.5}
          alignItems="flex-end"
          sx={{
            pt: 2.5,
          }}
        >
          <TextField select fullWidth label="Tarjetas guardadas" SelectProps={{ native: true }}>
            {CARD_OPTIONS.map((card) => (
              <option key={card.value} value={card.value}>
                {card.label}
              </option>
            ))}
          </TextField>

          <Button
            size="small"
            color="primary"
            startIcon={<Iconify icon="mingcute:add-line" />}
            onClick={onOpen}
          >
            Añadir nueva tarjeta
          </Button>
        </Stack>
      )}
    </Paper>
  );
}

OptionItem.propTypes = {
  isCredit: PropTypes.bool,
  onOpen: PropTypes.func,
  option: PropTypes.object,
  selected: PropTypes.bool,
};
