import PropTypes from 'prop-types';
// @mui
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import { alpha } from '@mui/material/styles';
//
//
import CustomStepper from 'src/components/custom-stepper/custom-stepper';
import { HeaderSimple as Header } from '../_common';

// ----------------------------------------------------------------------

export default function AuthModernCompactStepLayout({ children, customMaxWidth, stepper=true }) {
  return (
    <>
      <Header />

      <Box
        component="main"
        sx={{
          py: 10,
          px: 2,
          display: 'flex',
          minHeight: '100vh',
          position: 'relative',
          alignItems: 'center',
          justifyContent: 'center',
          '&:before': {
            width: 1,
            height: 1,
            zIndex: -1,
            content: "''",
            opacity: 0.24,
            position: 'absolute',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
            backgroundImage: 'url(/assets/background/overlay_4.jpg)',
          },
        }}
      >
        <Card
          sx={{
            p: 3,
            width: customMaxWidth || 600,
          }}
        >
          {stepper && (<CustomStepper activeStep={0} totalSteps={2}/>)}
          <Paper
            sx={{
              p: 2,
              my: 3,
              minHeight: 120,
              bgcolor: (theme) => alpha(theme.palette.grey[400], 0.12),
            }}
          >
            {children}
          </Paper>
        </Card>
      </Box>
    </>
  );
}

AuthModernCompactStepLayout.propTypes = {
  children: PropTypes.node,
  customMaxWidth: PropTypes.number,
  stepper: PropTypes.bool
};
