import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
// mui
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { Backdrop, CircularProgress } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// icons
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined'; // Asalariado
import Person2OutlinedIcon from '@mui/icons-material/Person2Outlined'; // autonomous
// utils
import { getUuid } from 'src/utils/get-uuid';
import ClientService from '../../services/client';
import UploadDocuments from './load-documents';

export default function DocumentView() {
  const uuid = getUuid();
  const theme = useTheme();

  const [loading, setLoading] = useState(true);
  // const [client, setClient] = useState(true);
  const [step, setStep] = useState(1);
  const [type, setType] = useState(1);
  const [startType, setStartType] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await ClientService.get(uuid);
        console.log('Response:', response);
        if (response?.client_type) {
          setType(response.client_type === 'asalariado' ? 'salaried' : 'autonomous');
          setStep(2);
          setStartType(false);
        }
      } catch (error) {
        console.error('Error fetching client info:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [uuid]);

  const changeStep = async (changeType) => {
    setType(changeType);
    await ClientService.changeType(uuid, changeType === "salaried" ? "asalariado" : "autonomo")
    setStep(2);
  };
  return (
    <>
      {loading && (
        <Backdrop sx={{ color: '#fffff', zIndex: () => theme.zIndex.drawer + 1 }} open>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      {step === 1 && (
        <>
          <Stack spacing={1} textAlign="center" alignItems="center">
            <Typography variant="h3">Documentos oficiales</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Selecciona una de las siguientes opciones
            </Typography>
            <Stack
              direction={{ xs: 'column', md: 'row' }}
              spacing={6}
              alignItems="center"
              justifyContent="center"
              sx={{ my: 1 }}
            >
              <Button
                onClick={() => changeStep('autonomous')}
                variant="text"
                size="large"
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  height: 'auto',
                  fontWeight: '500',
                  padding: '1.5rem',
                  fontSize: '1rem',
                  bgcolor: 'transparent',
                  color: '#F5A261',
                }}
              >
                <span style={{ marginBottom: '0.5rem' }}>¡Soy autónomo!</span>
                <Person2OutlinedIcon sx={{ fontSize: '3rem' }} />
              </Button>
              <Button
                onClick={() => changeStep('salaried')}
                variant="text"
                size="large"
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  height: 'auto',
                  fontWeight: '500',
                  padding: '1.5rem',
                  fontSize: '1rem',
                  bgcolor: 'transparent',
                  color: '#F5A261',
                }}
              >
                <span style={{ marginBottom: '0.5rem' }}>¡Soy asalariado!</span>
                <BadgeOutlinedIcon sx={{ fontSize: '3rem' }} />
              </Button>
            </Stack>
          </Stack>

          <Stack sx={{ textAlign: 'left' }} direction={{ xs: 'column', sm: 'row' }} spacing={1}>
            <Button component={Link} to={`/clients/landing/${uuid}`} variant="soft">
              Volver atrás
            </Button>
          </Stack>
        </>
      )}
      {step === 2 && (
        <UploadDocuments documentType={type} uuid={uuid} setStep={setStep} startType={startType} />
      )}
    </>
  );
}
