import {useEffect, useState} from "react";
import PropTypes from 'prop-types';
import ErrorView, {errorAlert} from 'src/utils/error-dialog';
// @mui
import {alpha} from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import {FormKYC} from './kyc-form';
import {ButtonsKYC, StepperKYC} from './kyc-stepper';
import {useKyc} from './hooks';
import {getUuid} from "../../../utils/get-uuid";
import ClientService from "../../../services/client";

// ----------------------------------------------------------------------
export default function KYCComponent() {
  const {
    steps,
    questions,
    solution,
    activeStep,
    emptyValue,
    emptyQuestions,
    //
    defaultValues,
    handleBack,
    handleNext,
    handleOnChange,
    onSubmit,
    //
    openDialog,
    setOpenDialog,
    errorMsg,
    errorTitle,
    closeFunction,
    isLoading,
  } = useKyc();

  const uuid = getUuid();

  const [currentUser, setCurrentUser] = useState(undefined);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await ClientService.get(uuid);
        setCurrentUser(response);
        if ("age" in response) {

          handleOnChange(response.age, "age")
        }
      } catch (error) {
        console.error('Error fetching client info:', error);
      }
    };

    fetchData();
    // reRender is here to ensure the useEffect executing when the component re-renders
  }, [handleOnChange, uuid]);


  const renderCircularProgress = (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        mb: '1rem',
      }}
    >
      <CircularProgress size={30}/>
      <Typography variant="h6" align="center" sx={{ml: '10px', mt: '1rem'}}>
        Su solicitud está siendo gestionada
      </Typography>
    </Box>
  );

  const showResult = (
    <>
      {emptyQuestions.length > 0 ? (
        <EmptyQuestionsAlert emptyQuestions={emptyQuestions}/>
      ) : (
        renderCircularProgress
      )}
    </>
  );

  return (
    <>
      <StepperKYC
        activeStep={activeStep}
        steps={steps}
        questions={questions}
        emptyQuestions={emptyQuestions}
      />
      <Paper
        sx={{
          p: 2,
          my: 2,
          bgcolor: (theme) => alpha(theme.palette.grey[400], 0.12),
        }}
      >
        {activeStep === steps.length
          ? showResult
          : defaultValues && (
          <FormKYC
            activeStep={activeStep}
            questions={questions}
            onChange={handleOnChange}
            onNext={handleNext}
            onSubmit={onSubmit}
            defaultValues={defaultValues}
          />
        )}
      </Paper>
      <ButtonsKYC
        activeStep={activeStep}
        steps={steps}
        solution={solution}
        handleBack={handleBack}
        handleNext={handleNext}
        emptyValue={emptyValue}
        isLoading={isLoading}
      />
      <ErrorView
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        errorMsg={errorMsg}
        errorTitle={errorTitle}
        closeFunction={closeFunction}
      />
    </>
  );
}

const EmptyQuestionsAlert = ({emptyQuestions}) => (
  <>
    <Alert key="warning" severity="warning" variant="outlined" sx={{width: 1}}>
      Para poder asignarte una solución es necesario que contestes todas las preguntas.
    </Alert>
    <Typography variant="body2" sx={{my: 1}}>
      Tienes pendiente por responder las preguntas:
    </Typography>
    <ul>
      {emptyQuestions.map((question, index) => (
        <li key={index}> {question.label ? question.label : question.name} </li>
      ))}
    </ul>
  </>
);

EmptyQuestionsAlert.propTypes = {
  emptyQuestions: PropTypes.array.isRequired,
};

const SolutionDetails = ({solution}) => (
  <>
    <Typography textAlign="center">¡Gracias por responder el cuestionario!</Typography>
    {solution.document_types?.length === 0 ? (
      <Typography textAlign="justify">
        Por el momento no es necesario realizar la carga de documentos, nosotros nos pondremos en
        contacto contigo a la brevedad.
      </Typography>
    ) : (
      <>
        <Typography textAlign="center">
          Te hemos asignado la solución <strong>{solution.name}.</strong>
        </Typography>
        <Typography textAlign="center">
          Te invitamos a subir los siguientes archivos a través de la vista de documentos:
        </Typography>
        <ul>
          {solution.document_types.map((document, index) => (
            <li key={index}>{document.name}</li>
          ))}
        </ul>
      </>
    )}
  </>
);

SolutionDetails.propTypes = {
  solution: PropTypes.object.isRequired,
};
