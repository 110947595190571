// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '',
  AGENTS: '/agents',
  CLIENTS: '/clients',
  ADMINS: '/admins',
  ANALYSTS: '/analysts',
};

// ----------------------------------------------------------------------

export const paths = {
  // AUTH
  auth: {
    login: `${ROOTS.AUTH}/login`,
    signup: `${ROOTS.AUTH}/signup`,
    forgotPassword: `${ROOTS.AUTH}/forgot-password`,
    newPassword: `${ROOTS.AUTH}/new-password`,
    sendPassword: `${ROOTS.AUTH}/send-password`,
  },
  // CLIENTS
  clients: {
    root: ROOTS.CLIENTS,
    welcome: (uuid) => `${ROOTS.CLIENTS}/welcome/${uuid}`,

    files: (uuid) => `${ROOTS.CLIENTS}/files/${uuid}`,
    bank: (uuid) => `${ROOTS.CLIENTS}/bank/${uuid}`,
    kyc: (uuid) => `${ROOTS.CLIENTS}/questionnaire/${uuid}`,
    result: (uuid) => `${ROOTS.CLIENTS}/result/${uuid}`,
    payment: (uuid) => `${ROOTS.CLIENTS}/payment/${uuid}`,
    landing: (uuid) => `${ROOTS.CLIENTS}/landing/${uuid}`,
    filesLanding: (uuid) => `${ROOTS.CLIENTS}/filesLanding/${uuid}`,
  },
  // AGENTS
  agents: {
    root: ROOTS.AGENTS,
    form: `${ROOTS.AGENTS}/form`,
    editform: (uuid) => `${ROOTS.AGENTS}/form/${uuid}`,
    client: {
      select: (nif) => `${ROOTS.AGENTS}/client/${nif}`,
    },
  },
  // ADMINS
  admins: {
    root: ROOTS.ADMINS,
    rules: `${ROOTS.ADMINS}/rules`,
    client: {
      select: (nif) => `${ROOTS.ADMINS}/client/${nif}`,
    },
  },
  // ANALYST
  analyst: {
    root: ROOTS.ANALYSTS,
    rules: `${ROOTS.ANALYSTS}/rules`,
    reporting: `${ROOTS.ANALYSTS}/reporting`,
    client: {
      select: (nif) => `${ROOTS.ANALYSTS}/client/${nif}`,
    },
  },
};
