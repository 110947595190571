import { _mock } from './_mock';
import { _tags } from './assets';

// ----------------------------------------------------------------------

const GB = 1000000000 * 24;

const FILES = [
  'Documento 1',
  'Documento 2',
  'Documento 3',
  'Documento 4',
  // 'Documento 5',
  // 'Documento 6',
  // 'Documento 7',
  // 'Documento 8',
  // 'Documento 9',
  // 'Documento 10',
];


const URLS = [
  _mock.image.cover(1),
  // 'https://www.cloud.com/s/c218bo6kjuqyv66/design_suriname_2015.mp3',
  // 'https://www.cloud.com/s/c218bo6kjuqyv66/expertise_2015_conakry_sao-tome-and-principe_gender.mp4',
  'https://www.cloud.com/s/c218bo6kjuqyv66/money-popup-crack.pdf',
  _mock.image.cover(3),
  _mock.image.cover(5),
  // 'https://www.cloud.com/s/c218bo6kjuqyv66/large_news.txt',
  // 'https://www.cloud.com/s/c218bo6kjuqyv66/nauru-6015-small-fighter-left-gender.psd',
  // 'https://www.cloud.com/s/c218bo6kjuqyv66/tv-xs.doc',
  // 'https://www.cloud.com/s/c218bo6kjuqyv66/gustavia-entertainment-productivity.docx',
  // 'https://www.cloud.com/s/c218bo6kjuqyv66/vintage_bahrain_saipan.xls',
  // 'https://www.cloud.com/s/c218bo6kjuqyv66/indonesia-quito-nancy-grace-left-glad.xlsx',
  // 'https://www.cloud.com/s/c218bo6kjuqyv66/legislation-grain.zip',
  // 'https://www.cloud.com/s/c218bo6kjuqyv66/large_energy_dry_philippines.rar',
  // 'https://www.cloud.com/s/c218bo6kjuqyv66/footer-243-ecuador.iso',
  // 'https://www.cloud.com/s/c218bo6kjuqyv66/kyrgyzstan-04795009-picabo-street-guide-style.ai',
  // 'https://www.cloud.com/s/c218bo6kjuqyv66/india-data-large-gk-chesterton-mother.esp',
  // 'https://www.cloud.com/s/c218bo6kjuqyv66/footer-barbados-celine-dion.ppt',
  // 'https://www.cloud.com/s/c218bo6kjuqyv66/socio_respectively_366996.pptx',
  // 'https://www.cloud.com/s/c218bo6kjuqyv66/socio_ahead_531437_sweden_popup.wav',
  // 'https://www.cloud.com/s/c218bo6kjuqyv66/trinidad_samuel-morse_bring.m4v',
  _mock.image.cover(11),
  _mock.image.cover(17),
  'https://www.cloud.com/s/c218bo6kjuqyv66/xl_david-blaine_component_tanzania_books.pdf',
];

const SHARED_PERSONS = [...Array(20)].map((_, index) => ({
  id: _mock.id(index),
  name: _mock.fullName(index),
  email: _mock.email(index),
  avatarUrl: _mock.image.avatar(index),
  permission: index % 2 ? 'view' : 'edit',
}));

export const FILE_TYPE_OPTIONS = [
  // 'folder',
  'pdf',
  // 'txt',
  // 'zip',
  // 'audio',
  'image',
  // 'video',
  // 'word',
  // 'excel',
  // 'powerpoint',
  // 'photoshop',
  // 'illustrator',
];

const TYPE_FILES = ['pending', 'review', 'rejected', 'approved'];
// ----------------------------------------------------------------------

const shared = (index) =>
  (index === 0 && SHARED_PERSONS.slice(0, 5)) ||
  (index === 1 && SHARED_PERSONS.slice(5, 9)) ||
  (index === 2 && SHARED_PERSONS.slice(9, 11)) ||
  (index === 3 && SHARED_PERSONS.slice(11, 12)) ||
  [];


export const _files = FILES.map((name, index) => ({
  id: `${_mock.id(index)}_file`,
  name,
  url: URLS[index],
  status: index,
  shared: shared(index),
  tags: _tags.slice(0, 5),
  size: GB / ((index + 1) * 500),
  createdAt: _mock.time(index),
  modifiedAt: _mock.time(index),
  type: TYPE_FILES[index],
  isFavorited: _mock.boolean(index + 1),
}));

export const FILES_STATUS_OPTIONS = [
  { value: 0, label: 'Pendiente de carga' },
  { value: 1, label: 'Cargado' },
  { value: 2, label: 'Rechazado' },
  { value: 3, label: 'Aprobado' },
];

export const _allFiles = [..._files];
