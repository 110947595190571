import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

// SETUP COLORS

const GREY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
};

const TERTIARY = {
  lighter: '#96cec7',
  light: '#56b0a5',
  main: '#2d9d8f',
  dark: '#247d72',
  darker: '#164e47',
  contrastText: '#FFFFFF',
};

const SECONDARY = {
  lighter: '#fcf3c9',
  light: '#fbefb7',
  main: '#FAEBA5',
  dark: '#c8cbc84',
  darker: '#968d63',
  contrastText: '#FFFFFF',
};

const PRIMARY = {
  lighter: '#f9c7a0',
  light: '#f7b480',
  main: '#F5A261',
  dark: '#c4814d',
  darker: '#93613a',
  contrastText: '#FFFFFF',
};

const SIMPLAI = {
  array: ['#F5A261', '#2e9d8e',  '#234754'],
  main: '#F5A261',
  primary: '#F5A261',
  secondary: '#2e9d8e',
  tertiary: '#234754',
};

const INFO = {
  lighter: '#CAFDF5',
  light: '#61F3F3',
  main: '#00B8D9',
  dark: '#006C9C',
  darker: '#003768',
  contrastText: '#FFFFFF',
};

const SUCCESS = {
  lighter: '#D3FCD2',
  light: '#77ED8B',
  main: '#22C55E',
  dark: '#118D57',
  darker: '#065E49',
  contrastText: '#ffffff',
};

const WARNING = {
  lighter: '#FFF5CC',
  light: '#FFD666',
  main: '#FFAB00',
  dark: '#B76E00',
  darker: '#7A4100',
  contrastText: GREY[800],
};

const ERROR = {
  lighter: '#FFE9D5',
  light: '#FFAC82',
  main: '#FF5630',
  dark: '#B71D18',
  darker: '#7A0916',
  contrastText: '#FFFFFF',
};

const DISABLED = {
  lighter: '#C8C8C8',
  light: '#BABABA',
  main: '#9C9C9C',
  dark: '#969696',
  darker: '#7A7A7A',
  contrastText: '#FFFFFF',
};

const BLUE = {
  lighter: '#66a1e0',
  light: '#3282d6',
  main: '#66a1e0',
  dark: '#004fa3',
  darker: '#003b7a',
};

const LIME = {
  lighter: '#e7f9c0',
  light: '#c6df87',
  main: '#8bc34a',
  dark: '#5a9216',
  darker: '#336600',
};

const INDIGO = {
  lighter: '#c4d7ff',
  light: '#91a7ff',
  main: '#3f51b5',
  dark: '#002984',
  darker: '#001064',
};

const PURPLE = {
  lighter: '#d8baff',
  light: '#aa80ff',
  main: '#673ab7',
  dark: '#320b86',
  darker: '#1a0052',
};

const COMMON = {
  simplai: SIMPLAI,
  common: {
    black: '#000000',
    white: '#FFFFFF',
    lightBlue: '#3282d6',
  },
  primary: PRIMARY,
  secondary: SECONDARY,
  tertiary: TERTIARY,
  info: INFO,
  success: SUCCESS,
  warning: WARNING,
  error: ERROR,
  blue: BLUE,
  lime: LIME,
  indigo: INDIGO,
  purple: PURPLE,
  disabled: DISABLED,
  grey: GREY,
  divider: alpha(GREY[500], 0.2),
  action: {
    hover: alpha(GREY[500], 0.08),
    selected: alpha(GREY[500], 0.16),
    disabled: alpha(GREY[500], 0.8),
    disabledBackground: alpha(GREY[500], 0.24),
    focus: alpha(GREY[500], 0.24),
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

export function palette(mode) {
  const light = {
    ...COMMON,
    mode: 'light',
    text: {
      primary: GREY[800],
      secondary: GREY[600],
      tertiary: GREY[600],
      disabled: GREY[500],
    },
    background: {
      paper: '#FFFFFF',
      default: '#FFFFFF',
      neutral: GREY[200],
    },
    action: {
      ...COMMON.action,
      active: GREY[600],
    },
  };

  const dark = {
    ...COMMON,
    mode: 'dark',
    text: {
      primary: '#FFFFFF',
      secondary: GREY[500],
      tertiary: GREY[500],
      disabled: GREY[600],
    },
    background: {
      paper: GREY[800],
      default: GREY[900],
      neutral: alpha(GREY[500], 0.12),
    },
    action: {
      ...COMMON.action,
      active: GREY[500],
    },
  };

  return mode === 'light' ? light : dark;
}
