import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';

// sections
import DocumentView from 'src/pages/clients/document';
// ----------------------------------------------------------------------

export default function FilesPage() {
  return (
    <>
      <Helmet>
        <title> SophIA-Lite</title>
      </Helmet>

      <DocumentView />
    </>
  );
}
