/* eslint-disable no-else-return */
import {useMemo} from 'react';
// routes
import {paths} from 'src/routes/paths';
// components
import SvgColor from 'src/components/svg-color';
import {useAuthContext} from 'src/auth/hooks';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{width: 1, height: 1}}/>
);

const ICONS = {
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const {user} = useAuthContext();

  const isUserAgent = user && user.role === 'agent';
  const isUserAnalyst = user && user.role === 'analyst';
  const isUserAdmin = user && user.role === 'admin';

  const data = useMemo(
    () => [
      // OVERVIEW
      // ----------------------------------------------------------------------
      {
        subheader: '',
        items: [
          isUserAdmin && {
            title: 'Solicitudes',
            path: paths.agents.root,
            icon: ICONS.user,
          },
          isUserAdmin && {
            title: 'BI',
            path: paths.analyst.reporting,
            icon: ICONS.analytics,
          },
          isUserAgent && {
            title: 'Solicitudes',
            path: paths.agents.root,
            icon: ICONS.user,
          },
          isUserAnalyst && {
            title: 'Solicitudes',
            path: paths.analyst.root,
            icon: ICONS.user,
          },
          isUserAnalyst && {
            title: 'BI',
            path: paths.analyst.reporting,
            icon: ICONS.analytics,
          },
          // {
          //   title: 'Nueva solicitud',
          //   path: paths.agents.form,
          //   icon: ICONS.blog,
          // },
        ].filter(Boolean),
      },
    ],
    [isUserAgent, isUserAnalyst, isUserAdmin]
  );

  return data;
}
