/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import Step from '@mui/material/Step';
import Stepper from '@mui/material/Stepper';
import StepLabel from '@mui/material/StepLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
// theme
import { bgGradient } from 'src/theme/css';
// iconify
import Iconify from 'src/components/iconify/iconify';
// components
// ----------------------------------------------------------------------

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      ...bgGradient({
        startColor: theme.palette.secondary.main,
        endColor: theme.palette.secondary.main,
      }),
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      ...bgGradient({
        startColor: theme.palette.secondary.main,
        endColor: theme.palette.secondary.main,
      }),
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    borderRadius: 1,
    backgroundColor: theme.palette.divider,
  },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  zIndex: 1,
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.text.disabled,
  backgroundColor:
    theme.palette.mode === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
  ...(ownerState.active && {
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    color: theme.palette.common.white,
    ...bgGradient({
      startColor: ownerState.rejected ? theme.palette.error : theme.palette.primary.light,
      endColor: ownerState.rejected ? theme.palette.error : theme.palette.primary.dark,
    }),
  }),
  ...(ownerState.rejected && {
    backgroundColor: 'rgba(245, 88, 88,0.8)',
  }),
  ...(ownerState.completed &&
    !ownerState.rejected && {
      color: theme.palette.common.white,
      ...bgGradient({
        startColor: theme.palette.secondary.lighter,
        endColor: theme.palette.secondary.main,
      }),
    }),
}));

function ColorlibStepIcon({ active, completed, className, icon, days, rejected }) {
  return (
    <ColorlibStepIconRoot ownerState={{ completed, active, rejected }} className={className}>
      <Iconify
        icon={
          completed
            ? 'solar:check-circle-outline'
            : rejected
              ? 'solar:close-circle-outline'
              : days && days[String(icon)]
        }
        style={{ color: rejected ? 'inherit' : completed ? 'black' : 'inherit' }}
      />
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  completed: PropTypes.bool,
  icon: PropTypes.number,
  days: PropTypes.object,
  rejected: PropTypes.bool,
};

function getStepLabel(step, clientData, stepLabels, signedForm) {
  const labels = stepLabels[step];

  if (typeof labels === 'string') {
    return labels;
  }

  switch (step) {
    case 2:
      if (clientData?.process) {
        return labels[clientData.process] || labels.default;
      }
      return labels.default;

    case 3:
      if (clientData?.process === 'questionnaire') {
        if (clientData?.recomendation === 'DENEGAR') {
          return labels.default;
        }
        return labels.questionnaire.default;
      }
      if (clientData?.process === 'bank') {
        return labels.bank;
      }
      return labels.default;

    case 4:
      if (clientData?.documents && clientData?.documents.length > 0) {
        return labels.documents;
      }
      return labels.default;
    case 5:
      if (clientData?.documents) {
        return labels.documents;
      }
      return labels.default;

    case 6:
      if (signedForm) {
        return labels.documents;
      }
      return labels.default;

    default:
      return '';
  }
}

export default function HorizontalTimeline(data) {
  const { steps, clientData, activeStep, icons, stepLabels, signedForm } = data;
  return (
    clientData && (
      <Stack sx={{ width: '100%' }} spacing={4} className="days">
        <Card sx={{ textAlign: 'justify', p: 3 }}>
          <Stepper
            alternativeLabel
            activeStep={activeStep}
            connector={<ColorlibConnector />}
            sx={{ width: '100%' }}
          >
            {steps.map((_, index) => {

              const label = getStepLabel(index + 1, clientData, stepLabels, signedForm);
              const isRejected = label === 'Solicitud rechazada';
              return (
                <Step key={index}>
                  <StepLabel
                    StepIconComponent={(props) => (
                      <ColorlibStepIcon days={icons} rejected={isRejected} {...props} />
                    )}
                  >
                    {label}
                  </StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </Card>
      </Stack>
    )
  );
}
