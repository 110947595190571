import CryptoJS from 'crypto-js';


// function to encrypt the data to be sent 
export function encryptdata(data){
    const iv = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_CRYPTO_IV); // use the same iv and key in the front and in the back
    const derived_key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_CRYPTO_KEY); 
    
    // eslint-disable-next-line object-shorthand
    const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), derived_key, { iv: iv,mode: CryptoJS.mode.CBC }).toString();

    return (encrypted)  
}