import { React, useEffect, useState } from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import { Link } from 'react-router-dom';

// iconos
import FileIcon from '@mui/icons-material/AttachFile';
import FormIcon from '@mui/icons-material/ArticleOutlined';
import BankIcon from '@mui/icons-material/AccountBalanceOutlined';
// utils
import { errorAlert } from 'src/utils/error-dialog';
import { getUuid } from 'src/utils/get-uuid';
// service
import ClientService from 'src/services/client';

export default function WelcomeView() {
  const [currentUser, setCurrentUser] = useState(undefined);

  const [openDialog, setOpenDialog] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [errorTitle, setErrorTitle] = useState('');
  const [closeFunction, setCloseFunction] = useState();

  const uuid = getUuid();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await ClientService.get(uuid);
        setCurrentUser(response);
      } catch (error) {
        errorAlert(
          'Ocurrio un error inesperado',
          'Ha ocurrido un error inesperado al cargar la pagina',
          setErrorMsg,
          setErrorTitle,
          setOpenDialog
        );
        console.error('Error fetching client info:', error);
      }
    };

    fetchData();
    // reRender is here to ensure the useEffect executing when the component re-renders
  }, [uuid]);

  const WelcomePage = currentUser && (
    <>
      <Grid
        container
        spacing={5}
        direction={{ xs: 'column', md: 'row' }}
        // alignItems="center"
        // justifyContent="space-evenly"
        // style={{ height: '100%' }}
        // wrap="nowrap"
      >
        <Grid item xs={12} sm={6}>
          <Button
            component={Link}
            to={`/clients/bank/${uuid}`}
            variant="text"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              fontSize: '1rem',
              fontWeight: '500',
              padding: '1rem',
              height: 'auto',
              bgcolor: 'transparent',
              color: '#F5A261',
              minWidth: '130px',
            }}
          >
            <BankIcon sx={{ fontSize: '3rem', marginBottom: '0.5rem' }} />{' '}
            <Typography variant="body1" sx={{ textAlign: 'center' }}>
              Cuenta bancaria
            </Typography>
            <Typography variant="body1" sx={{ textAlign: 'center' }}>
              (Método más rápido)
            </Typography>
          </Button>
        </Grid>
        {/* <Grid item xs={12} sm={4}>
          <Button
            component={Link}
            to={`/clients/files/${uuid}`}
            variant="text"
            size="large"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              fontSize: '1rem',
              fontWeight: '500',
              padding: '1.5rem',
              height: 'auto',
              bgcolor: 'transparent',
              color: '#F5A261',
              minWidth: '130px',
            }}
          >
            <FileIcon sx={{ fontSize: '3rem', marginBottom: '0.5rem' }} />{' '}
            <Typography variant="body1" sx={{ textAlign: 'center' }}>
              Documentos Oficiales
            </Typography>{' '}
            <br />
          </Button>
        </Grid> */}
        <Grid item xs={12} sm={6}>
          <Button
            component={Link}
            to={`/clients/questionnaire/${uuid}`}
            variant="text"
            size="large"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              fontSize: '1rem',
              fontWeight: '500',
              padding: '1.5rem',
              height: 'auto',
              bgcolor: 'transparent',
              color: '#F5A261',
            }}
          >
            <FormIcon sx={{ fontSize: '3rem', marginBottom: '0.5rem' }} />{' '}
            <Typography variant="body1" sx={{ textAlign: 'center' }}>
              Cuestionario
            </Typography>{' '}
            <Typography variant="body1" sx={{ textAlign: 'center' }}>
              (Decisión en 24 horas)
            </Typography>{' '}
          </Button>
        </Grid>
      </Grid>

      <Grid item xs={12} sm={4} sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
        <Button
          component={Link}
          to={`/clients/landing/${uuid}`}
          variant="text"
          size="large"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            fontSize: '1rem',
            fontWeight: '500',
            padding: '1.5rem',
            height: 'auto',
            bgcolor: 'transparent',
            color: '#F5A261',
          }}
        >
          <Typography variant="body1" sx={{ textAlign: 'right' }}>
            Omitir este paso
          </Typography>
        </Button>
      </Grid>
    </>
  );

  return (
    currentUser && (
      <>
        <Typography variant="h3" textAlign="center">
          {`¡Bienvenido ${currentUser.name}!`}
        </Typography>
        <Typography variant="body2" textAlign="center" sx={{ color: 'text.secondary', m: 2 }}>
          Estás a un paso de conocer si podemos aprobar tu finaciación por un importe de:{' '}
          {currentUser.finance_amount.toLocaleString('es-ES', { style: 'currency', currency: 'EUR' })}
          <br />
          ¿Cómo te gustaría facilitarnos la información para realizar el estudio?
        </Typography>
        {WelcomePage}
      </>
    )
  );
}
