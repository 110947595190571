import PropTypes from 'prop-types';
import { useEffect, useReducer, useCallback, useMemo, useState } from 'react';
import io from 'socket.io-client';
import { useSnackbar } from 'notistack';
import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/paths';
import { useSocket } from './socket-context';

//
import { AuthContext } from './auth-context';
import { isValidToken, setSession } from './utils';

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

const initialState = {
  user: null,
  loading: true,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      loading: false,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGIN') {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      user: null,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

const STORAGE_KEY = 'accessToken';
const STORAGE_USER_KEY = 'user';
export function AuthProvider({ children }) {
  const router = useRouter();

  const [state, dispatch] = useReducer(reducer, initialState);
  const { connectSocket, disconnectSocket, receiveFileAlerts } = useSocket();

  const initialize = useCallback(async () => {
    try {
      const accessToken = sessionStorage.getItem(STORAGE_KEY);

      if (accessToken && isValidToken(accessToken)) {
        const userData = sessionStorage.getItem(STORAGE_USER_KEY);
        const dataUser = JSON.parse(userData);

        setSession(accessToken, dataUser);

        dispatch({
          type: 'INITIAL',
          payload: {
            user: {
              ...dataUser,
              accessToken,
            },
          },
        });
      } else {
        dispatch({
          type: 'INITIAL',
          payload: {
            user: null,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: 'INITIAL',
        payload: {
          user: null,
        },
      });
    }
  }, []);

  const socketConexion = useCallback(() => {
    const user = JSON.parse(sessionStorage.getItem('user'));
    if (user) {
      connectSocket(user.email);
    }
  }, [connectSocket]);
  useEffect(() => {
    initialize();
    socketConexion();
  }, [initialize, socketConexion]);

  // LOGIN
  const login = useCallback(
    async (accessToken) => {
      await setSession(accessToken);
      const user = JSON.parse(sessionStorage.getItem(STORAGE_USER_KEY));
      socketConexion();
      dispatch({
        type: 'LOGIN',
        payload: {
          user: {
            ...user,
            accessToken,
          },
        },
      });
    },
    [socketConexion]
  );

  // LOGOUT
  const logout = useCallback(async () => {
    console.log('LOGOUT LLEVA A ', paths.auth.login);

    disconnectSocket();
    sessionStorage.clear();

    dispatch({
      type: 'LOGOUT',
    });
    router.push(paths.auth.login);
  }, [router, disconnectSocket]);

  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      method: 'jwt',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      //
      login,
      logout,
    }),
    [login, logout, state.user, status]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node,
};
