import { useState } from 'react';
import * as React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { DialogContent, Slide } from '@mui/material';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import { Box, Stack } from '@mui/system';
import Typography from '@mui/material/Typography';
import DialogBankAccount from './dialog-bank-selector';

const banks = [
  {
    name: 'CaixaBank',
    img: 'https://companieslogo.com/img/orig/CABK.MC-581477ce.png?t=1593615138',
  },
  {
    name: 'Sabadell',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTS4ph8AaRtP7Aa35OkC-nZG9buRt8KpAgzgg&s',
  },
  {
    name: 'Santander',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSPh9xRSMPjrN2LdY3P0ZtRWeYCfbtPYPnXXQ&s',
  },
  // {
  //   name: 'Bankia',
  //   img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQeK-hOHZwkwV7YX1F6ipJn3G0R5_q5fdrnKg&s',
  // },
  {
    name: 'ING',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ00Amnh7mkccyzx8gGE_r-tCJC4dxWuWJNjQ&s',
  },
  {
    name: 'Liberbank',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ73e3SmPMfuy68XUxoae00VPbg8MQMtV186uNG9IS0ErHIKbyq-YwrxkSkrSKn3volAok&usqp=CAU',
  },
  {
    name: 'BBVA',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSLHIYnU6--l7YkooVRwvHgMG2408c5jtWguw&s',
  },
  {
    name: 'Bankinter',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQBuGUX_W6ouN64R6bnQMfkoXGEPApdDPWfWw&s',
  },
  {
    name: 'iberCaja',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR4Emde3PewkyEZ5u3rky627BjDww_nroq-kQ&s',
  },
  {
    name: 'ABANCA',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSxgNzIO1fDJ4q9iSf35E2zjGXCzyUtCzhLMg&s',
  },
  {
    name: 'Openbank',
    img: 'https://upload.wikimedia.org/wikipedia/commons/b/bb/Logo_sin_simplifica_LK_logo.png',
  },
];

const Img = styled('img')({
  // : 'auto',
  display: 'block',
  maxWidth: '100%',
  maxHeight: '100%',
});

const ImgBank = styled('img')({
  // : 'auto',
  width: '100px',
  height: '80px',
  'object-fit': 'contain',
});

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="right" ref={ref} {...props} />
));

export default function DialogBankSelector(props) {
  const { onClose, open, setOpenBank } = props;

  const [openDialogUnnaxAccount, setOpenDialogUnnaxAccount] = useState(false);
  const [selectedBank, setSelectedBank] = useState(null);

  const handleCloseAccount = (value) => {
    setOpenDialogUnnaxAccount(false);
  };
  const handleClose = () => {
    onClose();
  };

  const handleAccount = (bank) => {
    setSelectedBank(bank);
    setOpenDialogUnnaxAccount(true);
    onClose();
  };

  return (
    <>
      <Dialog
        onClose={handleClose}
        open={open}
        scroll="paper"
        TransitionComponent={Transition}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle>
          <Stack direction="column" alignItems="center" spacing={2} sx={{ mt: 3 }}>
            {/* <Img src="https://www.unnax.mx/wp-content/uploads/2021/06/Background.png" width={150} /> */}
            <Typography variant="h5">Selecciona tu banco</Typography>
          </Stack>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={1}>
            {banks.map((bank) => (
              <Grid item xs={6}>
                <Stack alignItems="center" sx={{ maxWidth: '100%' }}>
                  <Card onClick={() => handleAccount(bank)} sx={{ width: 150, height: 130 }}>
                    <Stack direction="column" spacing={1} alignItems="center" sx={{ p: 1 }}>
                      <ImgBank src={bank.img} />

                      <Typography variant="body2">{bank.name}</Typography>
                    </Stack>
                  </Card>
                </Stack>
              </Grid>
            ))}
          </Grid>
        </DialogContent>
      </Dialog>

      <DialogBankAccount
        open={openDialogUnnaxAccount}
        onClose={handleCloseAccount}
        bank={selectedBank}
        setOpenBank={setOpenBank}
      />
    </>
  );
}

DialogBankSelector.propTypes = {
  onClose: PropTypes.func.isRequired,
  setOpenBank: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};
