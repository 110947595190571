import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// _mock
import { useAuthContext } from 'src/auth/hooks';
import { useSettingsContext } from 'src/components/settings';
import ClientService from 'src/services/client';
import { errorAlert } from 'src/utils/error-dialog';
// utils
import { getUuid } from 'src/utils/get-uuid';

// ----------------------------------------------------------------------

const validateEmptyValue = (question) =>
  question.type !== 'text' && question.value !== null && question.value.toString().trim() === '';

const questionsRequired = async (questions) => {
  const emptyValueObjects = [];
  questions.forEach((question) => {
    if (!question.parent_id && validateEmptyValue(question)) {
      emptyValueObjects.push(question);
    }

    if (question.parent_id) {
      const parent = questions.find((q) => q.id === question.parent_id);
      if (
        parent &&
        (question.type === 'time' ||
          (question.condition && question.condition.includes(parent.value))) &&
        validateEmptyValue(question)
      ) {
        emptyValueObjects.push(question);
      }
    }
  });

  return emptyValueObjects;
};

const mapQuestions = (questions, user) => {
  // const encodedEmail = btoa(user.email);
  const mappedQuestions = questions.map((question) => {
    const localStorageKey = `FLAG_KYC_${question.id}`;
    const localValue = JSON.parse(localStorage.getItem(localStorageKey));
    let value;

    if (question.default_value !== null && !localValue) {
      value = question.default_value;
    } else {
      value = localValue || (question.type !== 'multiselect' ? '' : []);
    }

    return {
      ...question,
      value,
    };
  });

  return mappedQuestions;
};

const clearAnswers = (questions, user) => {
  questions.forEach(({ id }) => {
    // const encodedEmail = btoa(user.email);
    localStorage.removeItem(`FLAG_KYC_${id}`);
  });
};

export default function useKyc() {
  const { user } = useAuthContext();
  const settings = useSettingsContext();
  const [defaultValues, setDefaultValues] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [emptyQuestions, setEmptyQuestions] = useState([]);
  const [steps, setSteps] = useState([]);
  const [emptyValue, setEmptyValue] = useState([]);
  const [solution, setSolution] = useState(undefined);
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [errorTitle, setErrorTitle] = useState('');
  const [closeFunction, setCloseFunction] = useState();

  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const uuid = getUuid();

  const isStepSkipped = (step) => skipped?.has(step);

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const response = await ClientService.questions(uuid);
        const questionsResponse = response.questions;
        const updatedQuestions = mapQuestions(questionsResponse, user);

        setSteps(response.steps);
        setQuestions(updatedQuestions);
        setDefaultValues(
          updatedQuestions.reduce((acc, question) => {
            acc[question.id] = question.value;
            return acc;
          }, {})
        );
      } catch (error) {
        setCloseFunction(null);
        errorAlert(
          'Ocurrio un error inesperado',
          'Ha ocurrido un error inesperado al cargar las preguntas',
          setErrorMsg,
          setErrorTitle,
          setOpenDialog
        );
        console.error('Error fetching client questions:', error);
      }
    };

    fetchQuestions();
  }, [user, uuid]);

  useEffect(() => {
    const validateQuestions = async () => {
      const empty = await questionsRequired(questions);
      setEmptyQuestions(empty);
    };

    if (questions.length > 0) {
      validateQuestions();
    }
  }, [questions]);

  const onSubmit = useCallback(
    async (data) => {
      try {
        if (emptyQuestions.length > 0) {
          setEmptyValue(emptyQuestions);
          return;
        }

        const formattedData = Object.values(data).map(
          ({ id, type, parent_id, condition, value }) => ({
            id,
            type,
            parent_id,
            condition,
            value,
          })
        );
        console.log('Respuestas del cuestionario:', formattedData);

        ClientService.answers({ uuid, answers: formattedData })
          .then((response) => {
            setSolution(response);
            clearAnswers(formattedData, user);
            return ClientService.process({ uuid, process: 'questionnaire' });
            // return ClientService.process({nif: user.nif process: 'questionnaire' });
          })
          .then((res) => {
            setIsLoading(true);
            setTimeout(() => {
              setIsLoading(false);
              navigate(`/clients/landing/${uuid}`);
            }, 4000);
          })
          .catch((error) => {
            errorAlert(
              'Ocurrio un error inesperado',
              'Ha ocurrido un error inesperado al enviar el cuestionario',
              setErrorMsg,
              setErrorTitle,
              setOpenDialog
            );
            console.error('Error fetching client kyc onSubmit:', error);
          })
          .finally(() => {
            setCloseFunction(null);
          });
      } catch (error) {
        console.error(error);
      }
    },
    [emptyQuestions, user, uuid, navigate]
  );

  const handleOnChange = useCallback(async (value, field) => {
    // const encodedEmail = btoa(user.email);
    localStorage.setItem(`FLAG_KYC_${field}`, JSON.stringify(value));
    setQuestions((prevValues) =>
      prevValues.map((item) => (item.id === field ? { ...item, value } : item))
    );
  }, []);

  const handleNext = () => {
    let newSkipped = skipped;

    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);

    if (activeStep >= steps.length - 1) {
      onSubmit(questions);
    }
  };

  const handleBack = () => {
    setEmptyValue([]);

    if (solution === null) {
      setSolution(undefined);
    }

    if (emptyValue.length > 0) {
      setActiveStep(emptyValue[0].step);
      return;
    }

    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return {
    steps,
    questions,
    solution,
    activeStep,
    emptyValue,
    emptyQuestions,
    //
    defaultValues,
    handleBack,
    handleNext,
    handleOnChange,
    onSubmit,
    //
    openDialog,
    setOpenDialog,
    errorMsg,
    errorTitle,
    closeFunction,
  };
}
